import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SnackbarService } from '../../../../shared/components/snackbar/snackbar.service';
import { VisoUserService } from '../../../../entities/viso-user';
import {
    archiveRelationshipsRequest,
    archiveRelationshipsRequestFailed,
    archiveRelationshipsRequestSuccess,
    assignRelationshipsToTierRequestSuccess,
    cancelAssessmentsForRelationshipsRequest,
    cancelAssessmentsForRelationshipsRequestSuccess,
    disableArtifactUpdatesForRelationshipsRequest,
    disableArtifactUpdatesForRelationshipsRequestSuccess,
    enableArtifactUpdatesForRelationshipsRequest,
    enableArtifactUpdatesForRelationshipsRequestSuccess,
    getRelationshipCountsRequest,
    getRelationshipCountsRequestFailed,
    getRelationshipCountsRequestSuccess,
    getRiskNetworkGraphRequest,
    getRiskNetworkGraphRequestFailed,
    getRiskNetworkGraphRequestSuccess,
    getVendorByIdRequest,
    getVendorByIdRequestFailed,
    getVendorByIdRequestSuccess,
    offboardRelationshipsRequest,
    offboardRelationshipsRequestFailed,
    offboardRelationshipsRequestSuccess,
    orgClientsQueryRequest,
    orgClientsQuerySuccess,
    queryRelationshipsForCsvRequest,
    queryRelationshipsForCsvRequestFailed,
    queryRelationshipsForCsvRequestSuccess,
    relationshipsQueryFailed,
    relationshipsQueryRequest,
    relationshipsQuerySuccess,
    sendAssessmentsForRelationshipsRequest,
    sendAssessmentsForRelationshipsRequestSuccess,
    visoUsersQueryRequest,
    visoUsersQuerySuccess,
} from '../relationships.actions';
import { EMPTY, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { VendorRelationshipsService } from '../../relationships.service';
import { OrgService } from '../../../../entities/org';
import { RelationshipService } from '../../../../entities/relationship';
import { ConfirmDialogService } from '../../../../shared/components/confirm-dialog/confirm-dialog.service';
import { ROUTER_NAVIGATED, RouterNavigationAction } from '@ngrx/router-store';
import { NgbModalWrapperService } from '../../../../shared';
import { WelcomeMessageComponent } from '../../welcome-message/welcome-message.component';
import { AssessmentWelcomeMessageComponent } from '../../assessment-welcome-message/assessment-welcome-message.component';
import { MatConfirmDialogService } from '../../../../shared/components/mat-confirm-dialog/mat-confirm-dialog.service';

@Injectable()
export class RelationshipsEffects {
    /**
     * Relationships Query Request Effect
     * Triggers when `relationshipsQueryRequest` gets dispatched
     * Executes `RelationshipsService.query()` to retrieve the results
     */
    relationshipsQueryRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(relationshipsQueryRequest),
            switchMap((action) =>
                this._vendorRelationshipService.query(action.params).pipe(
                    map((response) =>
                        relationshipsQuerySuccess({
                            results: response.body,
                            headers: response.headers,
                        }),
                    ),
                    catchError(() => of(relationshipsQueryFailed())),
                ),
            ),
        ),
    );

    /**
     * Convert to Csv Request Effect
     * Triggers when `convertToCsvRequest` gets dispatched
     * Executes `RelationshipsService.query()` to retrieve results
     */
    convertToCsvRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(queryRelationshipsForCsvRequest),
            switchMap((action) =>
                this._vendorRelationshipService.query(action.params).pipe(
                    map((response) => queryRelationshipsForCsvRequestSuccess({ results: response.body })),
                    catchError(() => of(queryRelationshipsForCsvRequestFailed())),
                ),
            ),
        ),
    );

    /**
     * Viso Users Query Request Effect
     * Triggers when `visoUsersQueryRequest` gets dispatched
     * Executes `VisoUserService.query()` to retrieve the results
     */
    visoUsersQueryRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(visoUsersQueryRequest),
            switchMap(() =>
                this._visoUserService.findAllForOrg().pipe(
                    map((response) => visoUsersQuerySuccess({ results: response.body })),
                    catchError(() => EMPTY),
                ),
            ),
        ),
    );

    /**
     * Org Clients Query Request Effect
     * Triggers when `orgClientsQueryRequest` gets dispatched
     * Executes `_orgService.clients()` to retrieve the results
     */
    orgClientsQueryRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(orgClientsQueryRequest),
            switchMap(() =>
                this._orgService.clients().pipe(
                    map((response) => orgClientsQuerySuccess({ results: response.body })),
                    catchError(() => EMPTY),
                ),
            ),
        ),
    );

    sendAssessmentsForRelationships$ = createEffect(() =>
        this._actions$.pipe(
            ofType(sendAssessmentsForRelationshipsRequest),
            switchMap(({ ids, assessmentsInProgress }) =>
                this._matConfirmDialogService
                    .confirm({
                        title: 'Start Assessments',
                        message:
                            'Please note that depending on the number of selected relationships, assessment completion times may vary.',
                        confirmLabel: 'Start assessments',
                    })
                    .pipe(
                        filter(Boolean),
                        switchMap(() =>
                            assessmentsInProgress
                                ? this._matConfirmDialogService.confirm({
                                      title: 'Assessments In Progress',
                                      message:
                                          'One or more assessments are already in progress. Do you want to cancel them and start new ones?',
                                      confirmLabel: 'Start assessments',
                                  })
                                : of(true),
                        ),
                        filter(Boolean),
                        switchMap(() =>
                            this._relationshipService.startAssessments(ids).pipe(
                                map((response) => sendAssessmentsForRelationshipsRequestSuccess({ ids, response })),
                                catchError(() => EMPTY),
                            ),
                        ),
                    ),
            ),
        ),
    );

    sendAssessmentsForRelationshipsSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(sendAssessmentsForRelationshipsRequestSuccess),
                tap(({ ids, response }) => {
                    this._snackbarService.success(
                        `${response.affectedCount} ${
                            response.affectedCount === 1 ? 'assessment started' : 'assessments started'
                        }. ${
                            !response.allSuccessful
                                ? ` <br>
                                    ${
                                        response.allExceeded > 0
                                            ? `${response.allExceeded} exceeded available assessments. <br>`
                                            : ''
                                    }
                                    ${
                                        response.allMissing3pContact > 0
                                            ? `${response.allMissing3pContact} missing Third Party contact and could not be started. <br>`
                                            : ''
                                    }
                                    ${
                                        response.allDrDoesntExist > 0
                                            ? `${response.allDrDoesntExist} missing Relationship.<br>`
                                            : ''
                                    }
                                    ${
                                        response.allDrNoContextTypes > 0
                                            ? `${response.allDrNoContextTypes} missing Business Case.<br>`
                                            : ''
                                    }
                                    ${
                                        response.allDrNoDataTypes > 0
                                            ? `${response.allDrNoDataTypes} missing Data Types.<br>`
                                            : ''
                                    }
                                `
                                : ''
                        }`,
                    );
                }),
            ),
        { dispatch: false },
    );

    cancelAssessmentsForRelationships$ = createEffect(() =>
        this._actions$.pipe(
            ofType(cancelAssessmentsForRelationshipsRequest),
            switchMap(({ ids, showConfirmationModal }) => {
                let obs = of(true);
                if (showConfirmationModal) {
                    obs = this._confirmDialogService
                        .confirm({
                            title: 'Cancel Assessments',
                            message: 'Are you sure you want to cancel the existing assessments?',
                            confirmLabel: 'Yes',
                        })
                        .pipe(filter((result) => result));
                }
                return obs.pipe(
                    switchMap(() =>
                        this._relationshipService.cancelAssessments(ids).pipe(
                            map((response) => cancelAssessmentsForRelationshipsRequestSuccess({ ids, response })),
                            catchError(() => EMPTY),
                        ),
                    ),
                );
            }),
        ),
    );

    cancelAssessmentsForRelationshipsRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(cancelAssessmentsForRelationshipsRequestSuccess),
                tap(({ ids, response }) => {
                    this._snackbarService.success(
                        `${response.affectedCount} ${
                            response.affectedCount === 1 ? 'assessment cancelled' : 'assessments cancelled'
                        }.<br>${
                            !response.allSuccessful
                                ? `${ids.length - response.affectedCount} ${
                                      ids.length - response.affectedCount === 1 ? 'relationship' : 'relationships'
                                  } did not have an assessment in progress and could not be cancelled.`
                                : ''
                        }`,
                    );
                }),
            ),
        { dispatch: false },
    );

    disableArtifactUpdatesForRelationships$ = createEffect(() =>
        this._actions$.pipe(
            ofType(disableArtifactUpdatesForRelationshipsRequest),
            switchMap(({ ids, showConfirmationModal }) => {
                let obs = of(true);
                if (showConfirmationModal) {
                    obs = this._confirmDialogService
                        .confirm({
                            title: 'Disable Artifact Updates',
                            message: 'Are you sure you want to disable Artifact Updates?',
                            confirmLabel: 'Yes',
                        })
                        .pipe(filter((result) => result));
                }
                return obs.pipe(
                    switchMap(() =>
                        this._relationshipService.disableArtifactUpdates(ids).pipe(
                            map((response) => disableArtifactUpdatesForRelationshipsRequestSuccess({ ids, response })),
                            catchError(() => EMPTY),
                        ),
                    ),
                );
            }),
        ),
    );

    disableArtifactUpdatesForRelationshipsRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(disableArtifactUpdatesForRelationshipsRequestSuccess),
                tap(({ ids, response }) => {
                    this._snackbarService.success(
                        `${response.affectedCount} Artifact Updates disabled  ${
                            !response.allSuccessful
                                ? `<br/>${ids.length - response.affectedCount} ${
                                      ids.length - response.affectedCount === 1 ? 'relationship' : 'relationships'
                                  } could not disable Artifact Updates.`
                                : ''
                        }`,
                    );
                }),
            ),
        { dispatch: false },
    );

    enableArtifactUpdatesForRelationships$ = createEffect(() =>
        this._actions$.pipe(
            ofType(enableArtifactUpdatesForRelationshipsRequest),
            switchMap(({ ids, showConfirmationModal }) => {
                let obs = of(true);
                if (showConfirmationModal) {
                    obs = this._confirmDialogService
                        .confirm({
                            title: 'Enable Artifact Updates',
                            message: 'Are you sure you want to enable Artifact Updates?',
                            confirmLabel: 'Yes',
                        })
                        .pipe(filter((result) => result));
                }
                return obs.pipe(
                    switchMap(() =>
                        this._relationshipService.enableArtifactUpdates(ids).pipe(
                            map((response) => enableArtifactUpdatesForRelationshipsRequestSuccess({ ids, response })),
                            catchError(() => EMPTY),
                        ),
                    ),
                );
            }),
        ),
    );

    enableArtifactUpdatesForRelationshipsRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(enableArtifactUpdatesForRelationshipsRequestSuccess),
                tap(({ ids, response }) => {
                    this._snackbarService.success(
                        `${response.affectedCount} Artifact Updates enabled  ${
                            !response.allSuccessful
                                ? `<br/>${ids.length - response.affectedCount} ${
                                      ids.length - response.affectedCount === 1 ? 'relationship' : 'relationships'
                                  } could not enable Artifact Updates.`
                                : ''
                        }`,
                    );
                }),
            ),
        { dispatch: false },
    );

    openWelcomeMessageModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ROUTER_NAVIGATED),
                map((action: RouterNavigationAction) =>
                    action.payload.routerState.root.children.find((route) => route.outlet === 'popup'),
                ),
                distinctUntilChanged((prev, next) => prev?.routeConfig.path === next?.routeConfig.path),
                filter((route) => !!route && route.routeConfig.path === 'new-user'),
                tap(() => {
                    this._modalService.open<WelcomeMessageComponent>(
                        WelcomeMessageComponent,
                        {},
                        {
                            backdrop: 'static',
                            size: 'md',
                            keyboard: false,
                        },
                    );
                }),
            ),
        { dispatch: false },
    );

    openInitialAssessmentMessageModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ROUTER_NAVIGATED),
                map((action: RouterNavigationAction) =>
                    action.payload.routerState.root.children.find((route) => route.outlet === 'popup'),
                ),
                distinctUntilChanged((prev, next) => prev?.routeConfig.path === next?.routeConfig.path),
                filter((route) => !!route && route.routeConfig.path === 'first-assessment-created'),
                tap(() => {
                    this._modalService.open<AssessmentWelcomeMessageComponent>(
                        AssessmentWelcomeMessageComponent,
                        {},
                        { size: 'md' },
                    );
                }),
            ),
        { dispatch: false },
    );

    getRiskNetworkGraphRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getRiskNetworkGraphRequest),
            switchMap(({ searchString, networkExposureOrgId }) =>
                this._vendorRelationshipService.queryRiskNetworkGraph(searchString, networkExposureOrgId).pipe(
                    map((riskNetworkGraphNodes) => getRiskNetworkGraphRequestSuccess({ riskNetworkGraphNodes })),
                    catchError(() => of(getRiskNetworkGraphRequestFailed())),
                ),
            ),
        ),
    );

    getVendorByIdRequest = createEffect(() =>
        this._actions$.pipe(
            ofType(getVendorByIdRequest),
            switchMap(({ vendorId }) =>
                this._orgService.findVendorById(vendorId).pipe(
                    map((vendor) => getVendorByIdRequestSuccess({ vendor })),
                    catchError(() => of(getVendorByIdRequestFailed())),
                ),
            ),
        ),
    );

    getRelationshipCounts$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getRelationshipCountsRequest),
            switchMap(() =>
                this._vendorRelationshipService.getRelationshipCounts().pipe(
                    map((relationshipCounts) => getRelationshipCountsRequestSuccess({ relationshipCounts })),
                    catchError(() => of(getRelationshipCountsRequestFailed())),
                ),
            ),
        ),
    );

    refreshRelationshipCounts$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                assignRelationshipsToTierRequestSuccess,
                archiveRelationshipsRequestSuccess,
                offboardRelationshipsRequestSuccess,
            ),
            map(() => getRelationshipCountsRequest()),
        ),
    );

    archiveRelationshipsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(archiveRelationshipsRequest),
            switchMap(({ relationshipIds }) =>
                this._relationshipService.markAsArchived(relationshipIds).pipe(
                    map((response) => archiveRelationshipsRequestSuccess({ response })),
                    catchError(() => of(archiveRelationshipsRequestFailed())),
                ),
            ),
        ),
    );

    archiveRelationshipsSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(archiveRelationshipsRequestSuccess),
                tap(({ response }) => {
                    this._snackbarService.success(`${response.affectedCount} ${
                        response.affectedCount === 1 ? 'relationship archived' : 'relationships archived'
                    }.<br>
                    ${
                        response.allSuccessful
                            ? ''
                            : 'Only non-onboarded relationships without open assessments can be archived. Please cancel open assessments, mark the relationships as Not Onboarded, and try again.'
                    }`);
                }),
            ),
        { dispatch: false },
    );

    archiveRelationshipsRequestFailed$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(archiveRelationshipsRequestFailed),
                tap(() => this._snackbarService.error('Archiving relationships failed')),
            ),
        { dispatch: false },
    );

    offboardRelationshipsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(offboardRelationshipsRequest),
            switchMap(({ relationshipIds }) =>
                this._relationshipService.markAsNotOnboarded(relationshipIds).pipe(
                    map((response) => offboardRelationshipsRequestSuccess({ response })),
                    catchError(() => of(offboardRelationshipsRequestFailed())),
                ),
            ),
        ),
    );

    offboardRelationshipsSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(offboardRelationshipsRequestSuccess),
                tap(({ response }) => {
                    this._snackbarService.success(
                        `${response.affectedCount} ${
                            response.affectedCount === 1 ? 'relationship' : 'relationships'
                        } marked as Not Onboarded
                    <br>
                    ${response.allSuccessful ? '' : 'Only archived/onboarded relationships can be offboarded.'}`,
                    );
                }),
            ),
        { dispatch: false },
    );

    offboardRelationshipsRequestFailed$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(offboardRelationshipsRequestFailed),
                tap(() => this._snackbarService.error('Failed to offboard the selected relationship(s).')),
            ),
        { dispatch: false },
    );

    constructor(
        private _actions$: Actions,
        private _vendorRelationshipService: VendorRelationshipsService,
        private _visoUserService: VisoUserService,
        private _snackbarService: SnackbarService,
        private _orgService: OrgService,
        private _relationshipService: RelationshipService,
        private _confirmDialogService: ConfirmDialogService,
        private _modalService: NgbModalWrapperService,
        private _matConfirmDialogService: MatConfirmDialogService,
    ) {}
}
