<div class="content-heading">
    <div>
        Risk Model
        <small>Manage and update the risk model</small>
    </div>
</div>
<div class="row">
    <div class="col-md-6">
        <div class="card card-default">
            <div class="card-header">
                <p class="card-title">Refresh risk assessments</p>
            </div>
            <div class="card-body">
                <section class="demo-tools-loading" *ngIf="isSaving">
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="sk-spinner sk-spinner-pulse"></div>
                    </div>
                </section>
                <section *ngIf="!isSaving">
                    <form
                        [formGroup]="riskAssessmentRefreshForm"
                        name="riskAssessmentRefreshForm"
                        role="form"
                        (submit)="submit()"
                    >
                        <div class="form-group">
                            <label class="form-control-label" for="field_clientOrg">Client Org (optional)</label>
                            <app-vendor-search formControlName="vendorRef" [customOption]="false" />
                            <small>Leave blank to run for all orgs.</small>
                        </div>

                        <div class="form-group">
                            <label class="form-control-label" for="field_reasonDetail">Reason Detail</label>
                            <input
                                name="reasonDetail"
                                type="text"
                                class="form-control"
                                formControlName="reasonDetail"
                                placeholder="Detailed reason for execution"
                            />
                            <small
                                >This will display on the most recent node within the risk timeline for
                                relationships.</small
                            >
                        </div>

                        <mat-checkbox name="createNew" formControlName="createNew" id="field_createNew" color="primary">
                            Create New Risk Assessments?
                        </mat-checkbox>
                    </form>
                </section>
            </div>
            <div class="card-footer">
                <p class="text-error">Be careful, this will create new risk assessments and impact graphs.</p>
                <button
                    mat-button
                    visoButton="primary"
                    class="mt-2"
                    type="submit"
                    [disabled]="isSaving || riskAssessmentRefreshForm.invalid"
                    (click)="submit()"
                >
                    Refresh
                </button>
            </div>
        </div>
    </div>
</div>
