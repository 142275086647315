<div class="detection">
    <div
        *ngIf="detection.managementResponse && detection.detectionType === DetectionType.EXCEPTION"
        class="managementResponse"
    >
        <h4 class="subtitle2 cell-heading">Management Response</h4>
        <p>{{ detection.managementResponse }}</p>
    </div>
    <div *ngIf="detection.notes" class="language">
        <h4 class="subtitle2 cell-heading">Detected Language</h4>
        <p
            class="monospace language-text"
            [ngClass]="{
                multiline: detection.artifactType === ArtifactType.QUESTIONNAIRE_ARTIFACT,
            }"
        >
            "{{ detection.notes }}"
        </p>
        <!-- above p tag intentionally has no whitespace between tags for multi-line pre-wrap support -->
    </div>
    <div *ngIf="!!frameworkType" class="control-ids">
        <h4 class="subtitle2 cell-heading mb-2">Identifiers</h4>
        <p class="mat-text-secondary">{{ frameworkType | callback: getFrameworkTypeLabel }}</p>
        <p>{{ detection.frameworkMapping || '-' }}</p>
        <div class="source">
            <p class="source label">Source:</p>
            {{ detection.controlIds || '–' }}
        </div>
    </div>
    <div *ngIf="detection.subservicers?.length" class="subservicer">
        <h4 class="subtitle2 cell-heading">
            {{ detection.subservicers.length === 1 ? 'Subservicer' : 'Subservicers' }}
        </h4>
        <p>{{ subservicerNames }}</p>
    </div>
    <div *ngIf="detection.auditorComment" class="auditorComment">
        <h4 class="subtitle2 cell-heading">Auditor Comments</h4>
        <p>{{ detection.auditorComment }}</p>
    </div>
    <div class="type">
        <h4 class="subtitle2 cell-heading">Type</h4>
        <ng-container [ngSwitch]="detection.detectionType">
            <app-badge type="light-gray" class="normal-badge" *ngSwitchCase="DetectionType.NORMAL"> General </app-badge>
            <app-badge type="light-gray" class="exceptions-badge" *ngSwitchCase="DetectionType.EXCEPTION">
                Exception
            </app-badge>
            <app-badge type="light-gray" class="subservicer-badge" *ngSwitchCase="DetectionType.SUBSERVICE">
                Subservicer
            </app-badge>
            <app-badge
                type="light-gray"
                class="shared-responsibility-badge"
                *ngSwitchCase="DetectionType.SHARED_RESPONSIBILITY_MODEL"
            >
                Shared Responsibility
            </app-badge>
            <app-badge class="cuec-badge" *ngSwitchCase="DetectionType.CUEC"> CUEC </app-badge>
        </ng-container>
    </div>

    <div class="location">
        <h4 class="subtitle2 cell-heading">Location</h4>
        <ng-container *ngFor="let page of detection.viewerPage | callback: getPages; let isLast = last">
            <ng-container
                [ngTemplateOutlet]="artifactPageLinkRef"
                [ngTemplateOutletContext]="{
                    detectionId: detection.id,
                    targetPage: page,
                    artifactId: detection.artifactId,
                    artifactName: detection.artifactName,
                    hasSectionName: !!detection.sectionName,
                    isLast,
                }"
            ></ng-container>
        </ng-container>
        <span>
            {{ detection.sectionName }}
        </span>
        <span *ngIf="!detection.viewerPage && !detection.sectionName">–</span>
    </div>
    <div class="artifact-type">
        <h4 class="subtitle2 cell-heading">Artifact Type</h4>
        <ng-container
            *ngIf="
                detection.artifactType === ArtifactType.FILE_ARTIFACT ||
                detection.artifactType === ArtifactType.URL_ARTIFACT
            "
        >
            <p>
                {{ AuditReportType[detection.auditReportType] || '–' }}
            </p>
        </ng-container>
        <ng-container *ngIf="detection.artifactType === ArtifactType.QUESTIONNAIRE_ARTIFACT">
            <p>Questionnaire</p>
        </ng-container>
    </div>
    <div class="artifact">
        <h4 class="subtitle2 cell-heading">
            <em *ngIf="detection.artifactExpired" class="fa fa-exclamation-circle text-error me-1"></em>Artifact
        </h4>
        <div class="cell-body">
            <div [tooltip]="artifactTooltip">
                <a
                    *ngIf="
                        detection.artifactType === ArtifactType.FILE_ARTIFACT ||
                        detection.artifactType === ArtifactType.URL_ARTIFACT
                    "
                    class="viso-link"
                    (click)="
                        openArtifactPage({
                            artifactId: detection.artifactId,
                            artifactName: detection.artifactName,
                            selectedDetectionId: detection.id,
                        })
                    "
                >
                    {{ detection.artifactName }}
                </a>
                <a
                    *ngIf="detection.artifactType === ArtifactType.QUESTIONNAIRE_ARTIFACT"
                    exportRemovableAction
                    class="viso-link"
                    target="blank"
                    [routerLink]="['/questionnaire', detection.artifactId]"
                    [replaceUrl]="false"
                >
                    Open in new tab
                </a>
            </div>
            <app-assurance-level-meter
                *ngIf="detection.auditReportAssuranceLevel"
                [assuranceLevel]="detection.auditReportAssuranceLevel"
                [hideLabel]="true"
            />
        </div>
    </div>
</div>

<ng-template
    #artifactPageLinkRef
    let-detectionId="detectionId"
    let-targetPage="targetPage"
    let-artifactId="artifactId"
    let-artifactName="artifactName"
    let-hasSectionName="hasSectionName"
    let-isLast="isLast"
>
    <ng-container [ngSwitch]="targetPage | callback: getPageType">
        <ng-container *ngSwitchCase="'range'">
            <a
                class="viso-link"
                (click)="
                    openArtifactPage({
                        artifactId,
                        artifactName,
                        selectedDetectionId: detectionId,
                        viewerPage: targetPage.initialPage,
                    })
                "
            >
                p{{ targetPage.initialPage }}
            </a>
            -
            <a
                class="viso-link"
                (click)="
                    openArtifactPage({
                        artifactId,
                        artifactName,
                        selectedDetectionId: detectionId,
                        viewerPage: targetPage.finalPage,
                    })
                "
            >
                {{ targetPage.finalPage }}
            </a>
        </ng-container>
        <ng-container *ngSwitchCase="'page'">
            <a
                class="viso-link"
                (click)="
                    openArtifactPage({
                        artifactId,
                        artifactName,
                        selectedDetectionId: detectionId,
                        viewerPage: targetPage,
                    })
                "
            >
                p{{ targetPage }}
            </a>
        </ng-container>
        <ng-container *ngSwitchCase="'text'">
            <span>{{ targetPage }}</span>
        </ng-container>
        <ng-container>
            <span>
                {{ !isLast ? ', ' : hasSectionName ? '; ' : '' }}
            </span>
        </ng-container>
    </ng-container>
</ng-template>
