<a [routerLink]="eventLink" [queryParams]="eventLinkQueryParams">
    <div class="notification-body">
        <div *ngIf="isNew" class="notification-new"></div>
        <div class="notification-title">
            <span class="link text-accent text-bold">{{ dataText }} </span>
            <span class="text-accent">{{ notificationText }}</span>
        </div>
        <div class="notification-time text-muted text-sm">
            {{ humanizedCreatedDate }}
        </div>
        <div class="notification-action" (click)="markAsDismissed.emit($event)">
            <em class="fa fa-check x text-primary"></em>
        </div>
    </div>
</a>
