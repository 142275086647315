import { SuggestedContact } from '@entities/relationship';
import * as fromRoot from '../../../shared/redux/state';
import { AssessmentView } from '@entities/assessment';
import { PrimaryVendorContact } from '../../primary-vendor-contact';
import { InteractiveIqrResponse } from '../rdp-ai-question-answering/rdp-ai-question-answering.model';
import { VisoUser } from '@entities/viso-user';
import { HasEmails } from '../models/populated-assessment';
import { OrgAssessmentSummarySections } from '../../../admin/client-profile/client-profile.model';
import { FrameworkMapping, FrameworkMappingType } from '@entities/framework/models/framework-mapping.model';
import { Risk, RiskAssessment } from '@entities/risk-assessment';
import { BusinessUnit } from '@entities/business-unit';
import { DataType } from '@entities/data-type';
import { SupplementalQuestionnaireConfig } from '@entities/org';
import { VendorTier } from '@entities/vendor-tier';

export const relationshipStateKey = 'relationship';
export const iiqrResponsesStateKey = 'lastIiqrResponsesByRelationship';
export const frameworkMappingsStateKey = 'rdpFrameworkMappings';

export const initialState: RelationshipState = {
    relationshipId: null,
    primaryVendorContact: null,
    assessments: {
        latestAssessmentLoaded: false,
        latestAssessment: null,
        allAssessments: [],
        propertiesMap: {},
    },
    suggestedVendorContacts: [],
    artifactSupersession: new Map<number, number>(),
    businessOwner: null,
    subscribers: [],
    assessmentSummaryConfig: [],
    supplementalQuestionnaire: {
        usingOrgDefaults: false,
        questionnaires: [],
    },
    vendorName: null,
    clientId: null,
    riskAccepted: false,
    latestRiskAssessment: null,
    riskOverride: {
        riskLevel: null,
        lastAppliedByFirstName: null,
        lastAppliedByLastName: null,
        lastAppliedByEmail: null,
        lastAppliedDate: null,
    },
    businessUnits: [],
    clientContacts: [],
    clientDomains: [],
    dataTypes: [],
    vendorTier: null,
};

export const initialIiqrResponsesState: LastInteractiveIqrResponsesByRelationshipState = {};

export interface AppState extends fromRoot.AppState {
    [relationshipStateKey]: RelationshipState;
}

export interface RelationshipState {
    relationshipId: number;
    primaryVendorContact: PrimaryVendorContact;
    assessments: {
        latestAssessmentLoaded: boolean;
        latestAssessment: AssessmentView;
        allAssessments: AssessmentView[];
        propertiesMap: { [id: number | string]: HasEmails };
    };
    suggestedVendorContacts: SuggestedContact[];
    artifactSupersession: Map<number, number>;
    businessOwner: VisoUser;
    subscribers: VisoUser[];
    assessmentSummaryConfig: OrgAssessmentSummarySections[];
    supplementalQuestionnaire: {
        usingOrgDefaults: boolean;
        questionnaires: SupplementalQuestionnaireConfig[];
    };
    vendorName: string;
    clientId: number;
    riskAccepted: boolean;
    latestRiskAssessment: RiskAssessment;
    riskOverride: {
        riskLevel: Risk;
        lastAppliedByFirstName: string;
        lastAppliedByLastName: string;
        lastAppliedByEmail: string;
        lastAppliedDate: Date;
    };
    businessUnits: BusinessUnit[];
    clientContacts: VisoUser[];
    clientDomains: string[];
    dataTypes: DataType[];
    vendorTier: VendorTier;
}

export interface LastInteractiveIqrResponsesByRelationshipState {
    [relationshipId: number]: InteractiveIqrResponse;
}

export const initialRelationshipFrameworkMappingsState: RelationshipFrameworkMappingsState = {
    mappings: [],
};

export interface RelationshipFrameworkMappingsState {
    mappings: FrameworkMapping;

    [relationshipId: number]: FrameworkMappingType;
}
