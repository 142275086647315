import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
    Org,
    OrgDetailsResponse,
    SupplementalQuestionnaireConfig,
    VendorFromExistingRelationshipRequest,
} from './org.model';
import { createRequestOption, CSRFService } from '../../shared';
import { DateUtilsService } from '@shared/utils/date-utils.service';
import { BYPASS_SNACKBAR_ON_ERROR } from '../../blocks/interceptor/errorhandler.interceptor';
import { CompleteVendorSearchResult } from '@shared/vendor-components/models/vendor-search-result';
import { VisoUser } from '../viso-user';
import { UpdateVendorDetailsRequest, VendorDetailsResponse } from '../../routes/request/models/vendor-details.model';
import { DomainVerification, UpdatePreferredEmailDomainRequest } from '@entities/domain-verification';

export type EntityResponseType = HttpResponse<Org>;

@Injectable({
    providedIn: 'root',
})
export class OrgService {
    private resourceUrl = 'api/orgs';
    private vendorsResourceUrl = 'api/vendors';

    constructor(
        private _http: HttpClient,
        private _dateUtilsService: DateUtilsService,
        private _csrfService: CSRFService,
    ) {}

    clients(): Observable<HttpResponse<Org[]>> {
        return this._http.get<Org[]>(`${this.resourceUrl}/clients`, { observe: 'response' }).pipe(
            map((res: HttpResponse<Org[]>) => this.convertArrayResponse(res, this.convertItemFromServer)),
            map((res: HttpResponse<Org[]>) => (res.body ? res.clone({ body: this.sortOrgsArray(res.body) }) : res)),
        );
    }

    update(org: Org): Observable<EntityResponseType> {
        return this._http
            .put<Org>(this.resourceUrl, org, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    searchByKeyword(keyword?: string): Observable<CompleteVendorSearchResult[]> {
        const params: any = { page: 0, size: 100 };
        if (keyword) {
            params.keyword = keyword;
        }
        const options = createRequestOption(params);
        return this._http.get<CompleteVendorSearchResult[]>(`${this.vendorsResourceUrl}/keyword`, { params: options });
    }

    findVendorById(id: number): Observable<CompleteVendorSearchResult> {
        return this._http.get<CompleteVendorSearchResult>(`${this.vendorsResourceUrl}/${id}`);
    }

    searchByHomepageUrl(homepageUrl: string): Observable<CompleteVendorSearchResult> {
        const options = createRequestOption({ url: homepageUrl });
        return this._http.get<CompleteVendorSearchResult>(`${this.vendorsResourceUrl}/domain`, {
            params: options,
            context: new HttpContext().set(BYPASS_SNACKBAR_ON_ERROR, { forAnyStatus: true }),
        });
    }

    getVendorFromExistingRelationship(
        vendorFromExistingRelationshipRequest: VendorFromExistingRelationshipRequest,
    ): Observable<HttpResponse<Org>> {
        const options = createRequestOption({
            id: vendorFromExistingRelationshipRequest.id,
            name: vendorFromExistingRelationshipRequest.name,
        });
        return this._http
            .get<Org>(`${this.resourceUrl}/relationship`, {
                params: options,
                observe: 'response',
                context: new HttpContext().set(BYPASS_SNACKBAR_ON_ERROR, { forAnyStatus: true }),
            })
            .pipe(map((res: HttpResponse<Org>) => this.convertResponse(res)));
    }

    findUsersByOrgId(orgId: number): Observable<VisoUser[]> {
        return this._http.get<VisoUser[]>(`${this.resourceUrl}/${orgId}/viso-users`);
    }

    getCurrentOrg(): Observable<HttpResponse<OrgDetailsResponse>> {
        return this._http.get<OrgDetailsResponse>(`${this.resourceUrl}/current`, { observe: 'response' });
    }

    uploadCompanyLogo(file: File): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);

        return this._http.post<any>(`${this.resourceUrl}/upload-logo`, formData, {
            headers: new HttpHeaders({
                name: 'X-XSRF-TOKEN',
                value: this._csrfService.getCSRF(),
            }),
            reportProgress: true,
            observe: 'events',
        });
    }

    uploadCompanyIcon(file: File): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);

        return this._http.post<any>(`${this.resourceUrl}/upload-icon`, formData, {
            headers: new HttpHeaders({
                name: 'X-XSRF-TOKEN',
                value: this._csrfService.getCSRF(),
            }),
            reportProgress: true,
            observe: 'events',
        });
    }

    deleteCompanyImages(): Observable<void> {
        return this._http.delete<void>(`${this.resourceUrl}/logo-icon`);
    }

    startImportProcess(): Observable<any> {
        return this._http.post(`${this.resourceUrl}/import`, { responseType: 'text' });
    }

    startOrgMetadataFetchingProcess(): Observable<any> {
        return this._http.post(`${this.resourceUrl}/metadata`, { responseType: 'text' });
    }

    getPreferredEmailDomain(): Observable<HttpResponse<DomainVerification>> {
        return this._http.get<DomainVerification>(`${this.resourceUrl}/email/domain`, { observe: 'response' });
    }

    updatePreferredEmailDomain(req: UpdatePreferredEmailDomainRequest): Observable<void> {
        return this._http.put<void>(`${this.resourceUrl}/email/domain`, req);
    }

    deletePreferredEmailDomain(): Observable<void> {
        return this._http.delete<void>(`${this.resourceUrl}/email/domain`);
    }

    getVendorDetails(orgId: number): Observable<VendorDetailsResponse> {
        return this._http.get<VendorDetailsResponse>(`${this.resourceUrl}/${orgId}/vendor-details`);
    }

    updateVendorDetails(
        updateRequest: UpdateVendorDetailsRequest,
        isCurrentUserAdmin: boolean,
    ): Observable<VendorDetailsResponse> {
        let adminSuffix = isCurrentUserAdmin ? '-admin' : '';
        return this._http.put<VendorDetailsResponse>(`${this.resourceUrl}/vendor-details${adminSuffix}`, updateRequest);
    }

    addDomain(orgId: number, domain: string): Observable<void> {
        return this._http.post<void>(`${this.resourceUrl}/${orgId}/domains?domain=${domain}`, null);
    }

    refreshVendorDetails(vendorId: number) {
        return this._http.post<boolean>(`${this.resourceUrl}/${vendorId}/vendor-details/refresh`, null);
    }

    findAllDomains(orgId: number): Observable<string[]> {
        return this._http.get<string[]>(`${this.resourceUrl}/${orgId}/domains`);
    }

    getOrgSupplementalQuestionnaireConfig(orgId: number): Observable<SupplementalQuestionnaireConfig[]> {
        return this._http.get<SupplementalQuestionnaireConfig[]>(
            `${this.resourceUrl}/${orgId}/supplemental-questionnaire-config`,
        );
    }

    setBrandingColor(hex: string): Observable<void> {
        return this._http.post<void>(`${this.resourceUrl}/branding-color`, null, { params: { color: hex } });
    }

    deleteBrandingColor(): Observable<void> {
        return this._http.delete<void>(`${this.resourceUrl}/branding-color`);
    }

    private convertResponse(res: EntityResponseType): EntityResponseType {
        const body: Org = this.convertItemFromServer(res.body);
        return res.clone({ body });
    }

    private convertArrayResponse<T>(res: HttpResponse<T[]>, convertItemFromServer: (a: T) => T): HttpResponse<T[]> {
        const jsonResponse: T[] = res.body;
        const body: T[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({ body });
    }

    /**
     * Convert a returned JSON object to Org.
     */
    private convertItemFromServer = (org: Org): Org => {
        const copy: Org = Object.assign({}, org);
        copy.createdDate = this._dateUtilsService.convertDateTimeFromServer(org.createdDate);
        copy.updatedDate = this._dateUtilsService.convertDateTimeFromServer(org.updatedDate);
        copy.subscriptionStartDate = this._dateUtilsService.convertDateTimeFromServer(org.subscriptionStartDate);
        copy.subscriptionEndDate = this._dateUtilsService.convertDateTimeFromServer(org.subscriptionEndDate);
        return copy;
    };

    private sortOrgsArray(orgs: Org[], property: keyof Org = 'name'): Org[] {
        return orgs.sort((a, b) => {
            const nameOrgA = (a[property] as string).toLowerCase();
            const nameOrgB = (b[property] as string).toLowerCase();
            if (nameOrgA < nameOrgB) {
                return -1;
            }
            if (nameOrgA > nameOrgB) {
                return 1;
            }
            return 0;
        });
    }
}
