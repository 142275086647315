import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';
import { relationshipsPopupRoute, relationshipsRoute } from './relationships.route';

import { RelationshipsComponent } from './relationships.component';
import { VendorRelationshipsService } from './relationships.service';
import { RelationshipsAdditionalFiltersPanelComponent } from './additional-filters-panel/additional-filters-panel.component';
import { RelationshipsEditColumnsComponent } from './edit-columns/edit-columns.component';
import { NgxSelectModule } from 'ngx-select-ex';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ArchwizardModule } from '@viso-trust/angular-archwizard';
import { relationshipsStateKey } from './redux/relationships-state';
import { RelationshipsEffects } from './redux/effects/relationships.effects';
import { relationshipsReducer } from './redux/relationships.reducer';
import { AssignBusinessOwnerModule } from '../business-owner/assign-business-owner.module';
import {
    RELATIONSHIPS_CONFIG_TOKEN,
    RELATIONSHIPS_LOCAL_STORAGE_KEY,
    RELATIONSHIPS_STORAGE_KEYS,
} from './redux/relationships.tokens';
import { getRelationshipConfig } from './redux/relationships.config';
import { LocalStorageService } from 'ngx-webstorage';
import { EditRelationshipThirdPartyContactComponent } from './edit-relationship-third-party-contact/edit-relationship-third-party-contact.component';
import { ThirdPartyContactFormComponent } from './third-party-contact-form/third-party-contact-form.component';
import { WelcomeMessageComponent } from './welcome-message/welcome-message.component';
import { AssessmentWelcomeMessageComponent } from './assessment-welcome-message/assessment-welcome-message.component';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { VisoCheckboxGroupModule } from '../../shared/viso-checkbox-group/viso-checkbox-group.module';
import { VendorComponentsModule } from '../../shared/vendor-components/vendor-components.module';
import { RiskNetworkComponent } from './risk-network/risk-network.component';
import { DisableAddRelationshipOnRulesModule } from '../../shared/disable-add-relationship-on-rules/disable-add-relationship-on-rules.module';
import { VisoPopoverModule } from '../../shared/viso-popover/viso-popover.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTab, MatTabGroup, MatTabLink, MatTabNav, MatTabNavPanel } from '@angular/material/tabs';
import { VendorTierStatsComponent } from './vendor-tier-stats/vendor-tier-stats.component';
import { MatDivider } from '@angular/material/divider';
import { VendorTierWalkthroughDialogComponent } from './vendor-tier-walkthrough-dialog/vendor-tier-walkthrough-dialog.component';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { CustomStepperModule } from '@shared/components/custom-stepper/custom-stepper.module';
import { CdkStep } from '@angular/cdk/stepper';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { VendorTierManagementDialogComponent } from './vendor-tier-management-dialog/vendor-tier-management-dialog.component';
import { VendorTierSelectionComponent } from './vendor-tier-selection/vendor-tier-selection.component';
import { VendorTierDescriptionDialogComponent } from './vendor-tier-description-dialog/vendor-tier-description-dialog.component';
import { MatDrawer, MatDrawerContainer } from '@angular/material/sidenav';
import { VendorTierEffects } from './redux/effects/vendor-tier.effects';

const ENTITY_STATES = [...relationshipsRoute, ...relationshipsPopupRoute];

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(ENTITY_STATES),
        NgxSelectModule,
        NgxDatatableModule,
        NgbModule,
        DragDropModule,
        BrowserAnimationsModule,
        ArchwizardModule,
        // Redux
        StoreModule.forFeature(relationshipsStateKey, relationshipsReducer, RELATIONSHIPS_CONFIG_TOKEN),
        EffectsModule.forFeature([RelationshipsEffects, VendorTierEffects]),
        AssignBusinessOwnerModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatInputModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        VisoCheckboxGroupModule,
        VendorComponentsModule,
        DisableAddRelationshipOnRulesModule,
        MatTooltipModule,
        VisoPopoverModule,
        MatTabGroup,
        MatTab,
        MatDivider,
        MatDialogClose,
        MatDialogContent,
        MatDialogTitle,
        MatDialogActions,
        CustomStepperModule,
        CdkStep,
        MatTabNav,
        MatTabLink,
        MatMenuTrigger,
        MatMenu,
        MatMenuItem,
        MatTabNavPanel,
        MatDrawer,
        MatDrawerContainer,
    ],
    declarations: [
        RelationshipsComponent,
        RelationshipsAdditionalFiltersPanelComponent,
        RelationshipsEditColumnsComponent,
        EditRelationshipThirdPartyContactComponent,
        ThirdPartyContactFormComponent,
        WelcomeMessageComponent,
        AssessmentWelcomeMessageComponent,
        RiskNetworkComponent,
        VendorTierStatsComponent,
        VendorTierWalkthroughDialogComponent,
        VendorTierManagementDialogComponent,
        VendorTierSelectionComponent,
        VendorTierDescriptionDialogComponent,
    ],
    providers: [
        VendorRelationshipsService,
        LocalStorageService,
        { provide: RELATIONSHIPS_STORAGE_KEYS, useValue: [] },
        { provide: RELATIONSHIPS_LOCAL_STORAGE_KEY, useValue: 'storage__' + relationshipsStateKey },
        {
            provide: RELATIONSHIPS_CONFIG_TOKEN,
            deps: [RELATIONSHIPS_LOCAL_STORAGE_KEY, RELATIONSHIPS_STORAGE_KEYS],
            useFactory: getRelationshipConfig,
        },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RelationshipsModule {}
