<ng-container *jhiHasAnyAuthority="[Roles.Auditor, Roles.Admin, Roles.Support]">
    <button
        *ngIf="showCreateRiskAdvisoryButton"
        class="me-2"
        type="button"
        mat-button
        color="warn"
        visoButton="secondary"
        (click)="openCreateRiskAdvisory.emit()"
    >
        <mat-icon>notifications</mat-icon>
        Create risk advisory
    </button>
</ng-container>
<ng-container *jhiHasNotAuthority="Roles.Admin">
    <ng-container *hideIfOrgUserIsNotBusinessOwner="businessOwner">
        <ng-container *ngIf="showStartAssessmentButton">
            <button
                *disableStartAssessmentOnRules="relationship"
                mat-button
                visoButton="secondary"
                color="primary"
                class="btn-labeled me-2 text-primary"
                (click)="startAssessment.emit()"
                data-pw="rdpStartAssessmentButton"
            >
                <span class="btn-label">
                    <i class="fa fa-paper-plane"></i>
                </span>
                Start Assessment
            </button>
        </ng-container>
    </ng-container>
    <ng-container *hideIfOrgUserIsNotBusinessOwner="businessOwner">
        <ng-container *ngIf="showRecertifyButton">
            <button
                *disableStartAssessmentOnRules="relationship"
                mat-button
                visoButton="secondary"
                color="primary"
                class="btn-labeled me-2 text-primary"
                (click)="confirmRecertifyAssessment.emit()"
                data-pw="recertifyAssessmentButton"
            >
                <span class="btn-label">
                    <i class="fa fa-paper-plane"></i>
                </span>
                Recertify Now
            </button>
        </ng-container>
    </ng-container>
    <ng-container *hideIfOrgUserIsNotBusinessOwner="businessOwner">
        <ng-container [ngSwitch]="relationshipStatus">
            <div *ngSwitchCase="RequestStatus.NOT_ONBOARDED" ngbDropdown class="d-inline-block">
                <button
                    *ngIf="showOnboardOffboardButton"
                    toggleDisableOnActions
                    ngbDropdownToggle
                    mat-button
                    visoButton="primary"
                    color="default"
                    class="text-primary"
                    id="drActionsButton"
                    [disabled]="isCurrentUserSupportOrReadOnly"
                    [disablingActions]="[MarkActions.markRequestAsArchivedRequest]"
                    [enablingActions]="[
                        MarkActions.markRequestAsArchivedRequestSuccess,
                        MarkActions.markRequestAsArchivedRequestFailed,
                    ]"
                >
                    Not Onboarded
                </button>
                <div ngbDropdownMenu aria-labelledby="drActionsButton">
                    <button
                        *disableOnboardOnRules="currentUser"
                        ngbDropdownItem
                        [disabled]="isCurrentUserSupportOrReadOnly"
                        (click)="onboard.emit()"
                    >
                        Onboard
                    </button>
                    <button ngbDropdownItem (click)="archive.emit()">Archive</button>
                </div>
            </div>
            <div *ngSwitchCase="RequestStatus.ONBOARDED" ngbDropdown class="d-inline-block">
                <button
                    *ngIf="showOnboardOffboardButton"
                    toggleDisableOnActions
                    ngbDropdownToggle
                    mat-button
                    visoButton="primary"
                    id="drActionsButton"
                    [disabled]="isCurrentUserSupportOrReadOnly"
                    [disablingActions]="[MarkActions.markRequestAsNotOnboardedRequest]"
                    [enablingActions]="[
                        MarkActions.markRequestAsNotOnboardedRequestSuccess,
                        MarkActions.markRequestAsNotOnboardedRequestFailed,
                    ]"
                >
                    Onboarded
                </button>
                <div ngbDropdownMenu aria-labelledby="drActionsButton">
                    <button
                        ngbDropdownItem
                        (click)="markAsNotOnboarded.emit()"
                        [disabled]="isCurrentUserSupportOrReadOnly"
                    >
                        Offboard
                    </button>
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
<button
    mat-button
    class="viso-button mdc-button mat-primary mat-mdc-unelevated-button mdc-button--unelevated mat-mdc-button-base ms-3"
    hoverClass="viso-button mdc-button mat-primary mdc-button--outlined mat-mdc-outlined-button mat-mdc-button-base ms-3"
    [replaceClasses]="true"
    [removeHoverClassOnClick]="true"
    tooltip="Print to PDF"
    placement="top"
    (click)="downloadPDF.emit()"
    [container]="'body'"
    [disabled]="onPrint"
>
    <em class="fa fa-file-pdf"></em>
</button>
