import { HttpHeaders } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { BulkStatusUpdateResponse } from '@entities/relationship/models/bulk-status-update-response';
import { Org } from '@entities/org';
import { VisoUser } from '@entities/viso-user';
import { RelationshipAdditionalFilters } from '../models/relationship-additional-filters.model';
import { RelationshipColumnGroup } from '../models/relationship-column.model';
import { RelationshipQueryRequest } from '../models/relationship-query-request.model';
import { RelationshipCounts, VendorRelationship } from '../models/relationship.model';
import { RiskNetworkGraphNode } from '../models/risk-network-graph-node';
import { CompleteVendorSearchResult } from '@shared/vendor-components/models/vendor-search-result';
import { VendorTier, VendorTierStats } from '@entities/vendor-tier';

export enum RelationshipsActions {
    RelationshipsQueryRequest = '[Relationships] Relationships Query Request',
    RelationshipsQuerySuccess = '[Relationships] Relationships Query Success',
    RelationshipsQueryFailed = '[Relationships] Relationships Query Failed',

    QueryRelationshipsForCsvRequest = '[Relationships] Query Relationships For CSV Request',
    QueryRelationshipsForCsvRequestSuccess = '[Relationships] Query Relationships For CSV Request Success',
    QueryRelationshipsForCsvRequestFailed = '[Relationships] Query Relationships For CSV Request Failed',

    SetColumnsState = '[Relationships] Set Columns State',
    SetAdditionalFiltersState = '[Relationships] Set Filters State',
    ClearAllFilters = '[Relationships] Clear All Filters',

    SendAssessmentsForRelationshipsRequest = '[Relationships] Send Assessments For Relationships Request',
    SendAssessmentsForRelationshipsRequestSuccess = '[Relationships] Send Assessments For Relationships Request Success',
    SendAssessmentsForRelationshipsRequestFailed = '[Relationships] Send Assessments For Relationships Request Failed',

    CancelAssessmentsForRelationshipsRequest = '[Relationships] Cancel Assessments For Relationships Request',
    CancelAssessmentsForRelationshipsRequestSuccess = '[Relationships] Cancel Assessments For Relationships Request Success',
    CancelAssessmentsForRelationshipsRequestFailed = '[Relationships] Cancel Assessments For Relationships Request Failed',

    DisableArtifactUpdatesForRelationshipsRequest = '[Relationships] Disable Artifact Updates For Relationships Request',
    DisableArtifactUpdatesForRelationshipsRequestSuccess = '[Relationships] Disable Artifact Updates For Relationships Request Success',
    DisableArtifactUpdatesForRelationshipsRequestFailed = '[Relationships] Disable Artifact Updates For Relationships Request Failed',

    EnableArtifactUpdatesForRelationshipsRequest = '[Relationships] Enable Artifact Updates For Relationships Request',
    EnableArtifactUpdatesForRelationshipsRequestSuccess = '[Relationships] Enable Artifact Updates For Relationships Request Success',
    EnableArtifactUpdatesForRelationshipsRequestFailed = '[Relationships] Enable Artifact Updates For Relationships Request Failed',

    GetRiskNetworkGraphRequest = '[Relationships] Get Risk Network Graph Request',
    GetRiskNetworkGraphRequestSuccess = '[Relationships] Get Risk Network Graph Request Success',
    GetRiskNetworkGraphRequestFailed = '[Relationships] Get Risk Network Graph Request Failed',

    VisoUsersQueryRequest = '[Relationships] Viso Users Query Request',
    VisoUsersQuerySuccess = '[Relationships] Viso Users Query Success',
    OrgClientsQueryRequest = '[Relationships] Org Clients Query Request',
    OrgClientsQuerySuccess = '[Relationships] Org Clients Query Success',

    GetVendorByIdRequest = '[Relationships] Get Vendor By Id',
    GetVendorByIdRequestSuccess = '[Relationships] Get Vendor By Id Success',
    GetVendorByIdRequestFailed = '[Relationships] Get Vendor By Id Failed',

    GetVendorTierStatsRequest = '[Relationships] Get Vendor Tier Stats Request',
    GetVendorTierStatsRequestSuccess = '[Relationships] Get Vendor Tier Stats Request Success',
    GetVendorTierStatsRequestFailed = '[Relationships] Get Vendor Tier Stats Request Failed',

    ClearVendorTierStats = '[Relationships] Clear Vendor Tier Stats',

    TryShowVendorTierWalkthrough = '[Relationships] Try Show Vendor Tier Walkthrough',

    UpdateVendorTiersCountRequest = '[Relationships] Update Vendor Tiers Count Request',
    UpdateVendorTiersCountSuccess = '[Relationships] Update Vendor Tiers Count Request Success',
    UpdateVendorTiersCountFailed = '[Relationships] Update Vendor Tiers Count Request Failed',

    OpenManageTiersDialog = '[Relationships] Open Manage Tiers Dialog',

    OpenAssignRelationshipsToTierDialog = '[Relationships] Open Assign Relationships to Tier Dialog',

    AssignRelationshipsToTierRequest = '[Relationships] Assign Relationships to Tier Request',
    AssignRelationshipsToTierRequestSuccess = '[Relationships] Assign Relationships to Tier Request Success',
    AssignRelationshipsToTierRequestFailed = '[Relationships] Assign Relationships to Tier Request Failed',

    OpenVendorTierDescriptionDialog = '[Relationships] Open Vendor Tier Description Dialog',

    UpdateVendorTierDescriptionRequest = '[Relationships] Update Vendor Tier Description Request',
    UpdateVendorTierDescriptionRequestSuccess = '[Relationships] Update Vendor Tier Description Request Success',
    UpdateVendorTierDescriptionRequestFailed = '[Relationships] Update Vendor Tier Description Request Failed',

    GetRelationshipCountsRequest = '[Relationships] Get Relationships Counts Request',
    GetRelationshipCountsRequestSuccess = '[Relationships] Get Relationships Counts Request Success',
    GetRelationshipCountsRequestFailed = '[Relationships] Get Relationships Counts Request Failed',

    ArchiveRelationshipsRequest = '[Relationships] Archive Relationships Request',
    ArchiveRelationshipsRequestSuccess = '[Relationships] Archive Relationships Request Success',
    ArchiveRelationshipsRequestFailed = '[Relationships] Archive Relationships Request Failed',

    OffboardRelationshipsRequest = '[Relationships] Offboard Relationships Request',
    OffboardRelationshipsRequestSuccess = '[Relationships] Offboard Relationships Request Success',
    OffboardRelationshipsRequestFailed = '[Relationships] Offboard Relationships Request Failed',

    GetRiskThresholdsForVendorTierStats = '[Relationships] Get Risk Thresholds For Vendor Tier Stats',
}

/**
 * Relationships Query Request Redux Action
 * Gets dispatched when a relationships query request is triggered.
 */
export const relationshipsQueryRequest = createAction(
    RelationshipsActions.RelationshipsQueryRequest,
    props<{ params: RelationshipQueryRequest }>(),
);

/**
 * Relationships Query Success Redux Action
 * Gets dispatched when a relationshipsQueryRequest action is successful.
 */
export const relationshipsQuerySuccess = createAction(
    RelationshipsActions.RelationshipsQuerySuccess,
    props<{ results: VendorRelationship[]; headers: HttpHeaders }>(),
);

/**
 * Relationships Query Failed Redux Action
 * Gets dispatched when a relationshipsQueryRequest action fails.
 */
export const relationshipsQueryFailed = createAction(RelationshipsActions.RelationshipsQueryFailed);

export const queryRelationshipsForCsvRequest = createAction(
    RelationshipsActions.QueryRelationshipsForCsvRequest,
    props<{ params: RelationshipQueryRequest }>(),
);

export const queryRelationshipsForCsvRequestSuccess = createAction(
    RelationshipsActions.QueryRelationshipsForCsvRequestSuccess,
    props<{ results: VendorRelationship[] }>(),
);

export const queryRelationshipsForCsvRequestFailed = createAction(
    RelationshipsActions.QueryRelationshipsForCsvRequestFailed,
);

/**
 * Set Filters State Redux Action
 * Gets dispatched when filters state change is triggered.
 */
export const setAdditionalFiltersState = createAction(
    RelationshipsActions.SetAdditionalFiltersState,
    props<{ filters: RelationshipAdditionalFilters }>(),
);

/**
 * Set Columns State Redux Action
 * Gets dispatched when a columns state change is triggered.
 */
export const setColumnsState = createAction(
    RelationshipsActions.SetColumnsState,
    props<{ results: RelationshipColumnGroup[] }>(),
);

/**
 * Clear All Filters Redux Action
 * Gets dispatched when clear all filters request is triggered inside relationships view.
 */
export const clearAllFilters = createAction(RelationshipsActions.ClearAllFilters);

/**
 * Viso Users Query Request Redux Action
 * Gets dispatched when viso users query request is triggered.
 */
export const visoUsersQueryRequest = createAction(RelationshipsActions.VisoUsersQueryRequest);

/**
 * Viso Users Query Success Redux Action
 * Gets dispatched when a `VisoUsersQueryRequest` is successful.
 */
export const visoUsersQuerySuccess = createAction(
    RelationshipsActions.VisoUsersQuerySuccess,
    props<{ results: VisoUser[] }>(),
);

/**
 * Org Clients Query Request Redux Action
 * Gets dispatched when org clients query request is triggered
 */
export const orgClientsQueryRequest = createAction(RelationshipsActions.OrgClientsQueryRequest);

/**
 * Org Clients Query Success Redux Action
 * Gets dispatched when a `OrgClientsQueryRequest` is successful.
 */
export const orgClientsQuerySuccess = createAction(
    RelationshipsActions.OrgClientsQuerySuccess,
    props<{ results: Org[] }>(),
);

export const sendAssessmentsForRelationshipsRequest = createAction(
    RelationshipsActions.SendAssessmentsForRelationshipsRequest,
    props<{ ids: number[]; assessmentsInProgress: boolean }>(),
);

export const sendAssessmentsForRelationshipsRequestSuccess = createAction(
    RelationshipsActions.SendAssessmentsForRelationshipsRequestSuccess,
    props<{ ids: number[]; response: BulkStatusUpdateResponse }>(),
);

export const sendAssessmentsForRelationshipsRequestFailed = createAction(
    RelationshipsActions.SendAssessmentsForRelationshipsRequestFailed,
);

export const cancelAssessmentsForRelationshipsRequest = createAction(
    RelationshipsActions.CancelAssessmentsForRelationshipsRequest,
    props<{ ids: number[]; showConfirmationModal: boolean }>(),
);

export const cancelAssessmentsForRelationshipsRequestSuccess = createAction(
    RelationshipsActions.CancelAssessmentsForRelationshipsRequestSuccess,
    props<{ ids: number[]; response: BulkStatusUpdateResponse }>(),
);

export const cancelAssessmentsForRelationshipsRequestFailed = createAction(
    RelationshipsActions.CancelAssessmentsForRelationshipsRequestFailed,
);

export const disableArtifactUpdatesForRelationshipsRequest = createAction(
    RelationshipsActions.DisableArtifactUpdatesForRelationshipsRequest,
    props<{ ids: number[]; showConfirmationModal: boolean }>(),
);

export const disableArtifactUpdatesForRelationshipsRequestSuccess = createAction(
    RelationshipsActions.DisableArtifactUpdatesForRelationshipsRequestSuccess,
    props<{ ids: number[]; response: BulkStatusUpdateResponse }>(),
);

export const disableArtifactUpdatesForRelationshipsRequestFailed = createAction(
    RelationshipsActions.DisableArtifactUpdatesForRelationshipsRequestFailed,
);

export const enableArtifactUpdatesForRelationshipsRequest = createAction(
    RelationshipsActions.EnableArtifactUpdatesForRelationshipsRequest,
    props<{ ids: number[]; showConfirmationModal: boolean }>(),
);

export const enableArtifactUpdatesForRelationshipsRequestSuccess = createAction(
    RelationshipsActions.EnableArtifactUpdatesForRelationshipsRequestSuccess,
    props<{ ids: number[]; response: BulkStatusUpdateResponse }>(),
);

export const enableArtifactUpdatesForRelationshipsRequestFailed = createAction(
    RelationshipsActions.EnableArtifactUpdatesForRelationshipsRequestFailed,
);

export const getRiskNetworkGraphRequest = createAction(
    RelationshipsActions.GetRiskNetworkGraphRequest,
    props<{ searchString?: string; networkExposureOrgId?: number }>(),
);

export const getRiskNetworkGraphRequestSuccess = createAction(
    RelationshipsActions.GetRiskNetworkGraphRequestSuccess,
    props<{ riskNetworkGraphNodes: RiskNetworkGraphNode[] }>(),
);

export const getRiskNetworkGraphRequestFailed = createAction(RelationshipsActions.GetRiskNetworkGraphRequestFailed);

export const getVendorByIdRequest = createAction(
    RelationshipsActions.GetVendorByIdRequest,
    props<{ vendorId: number }>(),
);

export const getVendorByIdRequestSuccess = createAction(
    RelationshipsActions.GetVendorByIdRequestSuccess,
    props<{ vendor: CompleteVendorSearchResult }>(),
);

export const getVendorByIdRequestFailed = createAction(RelationshipsActions.GetVendorByIdRequestFailed);

export const getVendorTierStatsRequest = createAction(
    RelationshipsActions.GetVendorTierStatsRequest,
    props<{ vendorTierId: number }>(),
);

export const getVendorTierStatsRequestSuccess = createAction(
    RelationshipsActions.GetVendorTierStatsRequestSuccess,
    props<{ vendorTierStats: VendorTierStats }>(),
);

export const getVendorTierStatsRequestFailed = createAction(RelationshipsActions.GetVendorTierStatsRequestFailed);

export const clearVendorTierStats = createAction(RelationshipsActions.ClearVendorTierStats);

export const tryShowVendorTierWalkthrough = createAction(RelationshipsActions.TryShowVendorTierWalkthrough);

export const updateVendorTiersCountRequest = createAction(
    RelationshipsActions.UpdateVendorTiersCountRequest,
    props<{ numberOfTiers: number }>(),
);

export const updateVendorTiersCountRequestSuccess = createAction(
    RelationshipsActions.UpdateVendorTiersCountSuccess,
    props<{ vendorTiers: VendorTier[] }>(),
);

export const updateVendorTiersCountRequestFailed = createAction(RelationshipsActions.UpdateVendorTiersCountFailed);

export const openManageTiersDialog = createAction(RelationshipsActions.OpenManageTiersDialog);

export const openAssignRelationshipsToTierDialog = createAction(
    RelationshipsActions.OpenAssignRelationshipsToTierDialog,
    props<{ relationships: VendorRelationship[]; tier?: VendorTier }>(),
);

export const assignRelationshipsToTierRequest = createAction(
    RelationshipsActions.AssignRelationshipsToTierRequest,
    props<{ relationshipIds: number[]; tier?: VendorTier }>(),
);

export const assignRelationshipsToTierRequestSuccess = createAction(
    RelationshipsActions.AssignRelationshipsToTierRequestSuccess,
);

export const assignRelationshipsToTierRequestFailed = createAction(
    RelationshipsActions.AssignRelationshipsToTierRequestFailed,
);

export const openVendorTierDescriptionDialog = createAction(
    RelationshipsActions.OpenVendorTierDescriptionDialog,
    props<{ vendorTierId: number }>(),
);

export const updateVendorTierDescriptionRequest = createAction(
    RelationshipsActions.UpdateVendorTierDescriptionRequest,
    props<{ vendorTierId: number; description: string }>(),
);

export const updateVendorTierDescriptionRequestSuccess = createAction(
    RelationshipsActions.UpdateVendorTierDescriptionRequestSuccess,
    props<{ vendorTier: VendorTier }>(),
);

export const updateVendorTierDescriptionRequestFailed = createAction(
    RelationshipsActions.UpdateVendorTierDescriptionRequestFailed,
);

export const getRelationshipCountsRequest = createAction(RelationshipsActions.GetRelationshipCountsRequest);

export const getRelationshipCountsRequestSuccess = createAction(
    RelationshipsActions.GetRelationshipCountsRequestSuccess,
    props<{ relationshipCounts: RelationshipCounts }>(),
);

export const getRelationshipCountsRequestFailed = createAction(RelationshipsActions.GetRelationshipCountsRequestFailed);

export const archiveRelationshipsRequest = createAction(
    RelationshipsActions.ArchiveRelationshipsRequest,
    props<{ relationshipIds: number[] }>(),
);

export const archiveRelationshipsRequestSuccess = createAction(
    RelationshipsActions.ArchiveRelationshipsRequestSuccess,
    props<{ response: BulkStatusUpdateResponse }>(),
);

export const archiveRelationshipsRequestFailed = createAction(RelationshipsActions.ArchiveRelationshipsRequestFailed);

export const offboardRelationshipsRequest = createAction(
    RelationshipsActions.OffboardRelationshipsRequest,
    props<{ relationshipIds: number[] }>(),
);

export const offboardRelationshipsRequestSuccess = createAction(
    RelationshipsActions.OffboardRelationshipsRequestSuccess,
    props<{ response: BulkStatusUpdateResponse }>(),
);

export const offboardRelationshipsRequestFailed = createAction(RelationshipsActions.OffboardRelationshipsRequestFailed);

export const getRiskThresholdsForVendorTierStats = createAction(
    RelationshipsActions.GetRiskThresholdsForVendorTierStats,
);
