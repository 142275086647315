import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-vendor-tier-description-dialog',
    templateUrl: './vendor-tier-description-dialog.component.html',
    styleUrl: './vendor-tier-description-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorTierDescriptionDialogComponent implements OnInit {
    @Input({ required: true })
    tierName: string;

    @Input({ required: true })
    currentDescription: string;

    newDescriptionControl: FormControl<string>;

    ngOnInit(): void {
        this.newDescriptionControl = new FormControl(this.currentDescription);
    }
}
