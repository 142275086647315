import { ChangeDetectionStrategy, Component, EventEmitter, input, Output, signal, ViewChild } from '@angular/core';
import { Risk } from '@entities/risk-assessment';
import { RiskThresholdData } from '../../risk-model-settings/redux/risk-model-settings.selectors';
import { RiskTolerance, RiskToleranceService } from '@entities/risk-tolerance';
import { MatDrawer } from '@angular/material/sidenav';
import { VendorTierStats } from '@entities/vendor-tier';

@Component({
    selector: 'app-vendor-tier-stats',
    templateUrl: './vendor-tier-stats.component.html',
    styleUrl: './vendor-tier-stats.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorTierStatsComponent {
    vendorTierStats = input.required<VendorTierStats>();
    riskTolerance = input.required<RiskTolerance>();
    riskToleranceThresholds = input.required<Map<RiskTolerance, Map<Risk, RiskThresholdData>>>();
    description = input.required<string>();

    @Output()
    updateDescription = new EventEmitter<void>();

    @Output()
    applyOnboardedFilter = new EventEmitter<void>();

    @Output()
    applyAssessedFilter = new EventEmitter<void>();

    private _riskColorsByScore: Partial<Record<Risk, string>> = {
        LOW: '#17b5ab',
        MEDIUM: '#2e71f3',
        HIGH: '#e47a3e',
        EXTREME: '#b70933',
    };

    get forVendorTier(): boolean {
        return !!this.vendorTierStats()?.vendorTierId;
    }

    @ViewChild('drawer', { static: false })
    drawer: MatDrawer;

    constructor(private _riskToleranceService: RiskToleranceService) {}

    disableDescriptionTooltip = signal<boolean>(false);

    openDrawer(): void {
        // Disable the tooltip for a brief moment on open so it doesn't
        // immediately show on shelf open (and thus show off-center of the description).
        this.disableDescriptionTooltip.set(true);
        this.drawer.toggle();
        setTimeout(() => this.disableDescriptionTooltip.set(false), 200);
    }

    getRiskColorHash(riskScore: number): string {
        if (!!this.riskTolerance() && !!this.riskToleranceThresholds()) {
            return this._riskColorsByScore[
                this._riskToleranceService.getRiskLevelFromScore(
                    riskScore,
                    this.riskTolerance(),
                    this.riskToleranceThresholds(),
                )
            ];
        }
    }

    showDescriptionTooltip(e: HTMLElement): boolean {
        return !this.disableDescriptionTooltip() && e.offsetHeight < e.scrollHeight;
    }
}
