<mat-expansion-panel [expanded]="defaultExpanded">
    <mat-expansion-panel-header class="button-pulse-overflow">
        <ng-template #reviewRiskBtn>
            <ng-container *hideIfOrgUserIsNotBusinessOwner="businessOwner">
                <ng-container *jhiHasAnyAuthority="Roles.OrgAdmin">
                    <div class="row" exportRemovableAction>
                        <div class="col">
                            <button
                                (click)="$event.stopPropagation(); reviewRisk.emit()"
                                class="me-2"
                                color="primary"
                                mat-flat-button
                            >
                                Review risk
                            </button>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-template>
        <mat-panel-title class="d-flex justify-content-between">
            <div>
                <strong class="mt-2">{{ assessmentTypeLabel }}</strong>
                <div class="text-muted full-basis" *ngIf="!!assessment">{{ interactivity }}</div>
            </div>
            <ng-container *hideIfOrgUserIsNotBusinessOwner="businessOwner">
                @if (showReviewFollowupButton) {
                    <button
                        *jhiHasAnyAuthority="[Roles.OrgAdmin, Roles.OrgUser]"
                        class="me-2 pulse"
                        mat-flat-button
                        color="primary"
                        (click)="$event.stopPropagation(); reviewFollowup.emit()"
                    >
                        Review follow-up
                    </button>
                } @else if (showEditFollowupButton) {
                    <button
                        *jhiHasAnyAuthority="[Roles.OrgAdmin, Roles.OrgUser]"
                        class="me-2"
                        mat-flat-button
                        color="primary"
                        (click)="$event.stopPropagation(); editFollowup.emit()"
                    >
                        Edit follow-up
                    </button>
                }
            </ng-container>
            @if (isExpanded && showReviewRiskButton) {
                <ng-container [ngTemplateOutlet]="reviewRiskBtn"></ng-container>
            }
        </mat-panel-title>
        <mat-panel-description *ngIf="!isExpanded && isAssessmentFinished">
            <div class="col">
                <div class="d-flex align-items-center">
                    <strong class="d-block me-1">
                        {{ assessmentStatus }}
                    </strong>
                    <mat-icon
                        *ngIf="assessment?.cancelledReason === AssessmentCancelledReason.NO_LONGER_DO_BUSINESS"
                        class="text-muted"
                        [matTooltip]="assessment?.assessmentStatusReasonDetail"
                        matTooltipPosition="right"
                        [inline]="true"
                        >info
                    </mat-icon>
                </div>
                <div class="text-muted">{{ finishedDate | date }}</div>
            </div>
            @if (showReviewRiskButton) {
                <ng-template [ngTemplateOutlet]="reviewRiskBtn"></ng-template>
            }
            <ng-container *ngIf="isAssessmentComplete">
                <div class="col-auto me-3">
                    <app-risk-chip class="ms-2" [risk]="completedResidualRisk" [condensed]="true" />
                </div>
                <div class="col-auto">
                    {{ recommendationsCount }}
                    <ng-container [ngPlural]="recommendationsCount">
                        <ng-template ngPluralCase="=0">Recommendations</ng-template>
                        <ng-template ngPluralCase="=1">Recommendation</ng-template>
                        <ng-template ngPluralCase="other">Recommendations</ng-template>
                    </ng-container>
                </div>
            </ng-container>
        </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="p-0 mb-3">
        <h4 class="mt-2">Events</h4>
        <app-assessment-status-timeline [assessment]="assessment" />
    </div>

    <div *ngIf="!assessment" class="text-center py-4 bg-light start-assessment-message">
        <p class="mb-0">
            Start an assessment to understand what controls are in place to mitigate the risk of this relationship.
        </p>
        <ng-container *jhiHasAnyAuthority="[Roles.OrgAdmin, Roles.OrgUser]">
            <ng-container *hideIfOrgUserIsNotBusinessOwner="businessOwner">
                <ng-container *ngIf="!isRelationshipArchived">
                    <button
                        *disableStartAssessmentOnRules="relationship"
                        exportRemovableAction
                        mat-button
                        visoButton="primary"
                        class="mt-2"
                        data-pw="assessmentTimeline-startAssessment"
                        (click)="startAssessment.emit()"
                    >
                        Start Assessment
                    </button>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>

    <mat-tab-group
        #assessmentTabGroup
        mat-stretch-tabs="false"
        class="viso-tabset"
        animationDuration="0"
        dynamicHeight
        [selectedIndex]="isAssessmentComplete ? ASSESSMENT_SUMMARY_TAB : EMAILS_TAB"
        *ngIf="!!assessment"
    >
        <mat-tab label="Assessment Summary" [disabled]="!isAssessmentComplete">
            <ng-template matTabContent>
                @if (isAssessmentComplete) {
                    <div class="row p-3 my-3 bg-light">
                        <div class="col">
                            <div class="row my-2" *ngIf="displayAssessmentOutdatedBanner">
                                <div class="col">
                                    <div class="text-bg-warning text-bold p-2">
                                        New Artifact Intelligence is available or the Relationship Context has changed,
                                        start a new assessment to have it included in the summary.
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col">
                                    <app-assessment-risk-diff
                                        class="d-block my-2"
                                        [completedRiskAssessment]="completedRiskAssessment"
                                    />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col">
                                    @if (!!recommendations.length) {
                                        <app-recommendations-list
                                            [recommendations]="recommendations"
                                            [vendorName]="vendorName"
                                        />
                                    } @else {
                                        <p>There are no recommendations for this relationship.</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row p-3 bg-light">
                        <div class="col">
                            <h4>Assessment Summary</h4>
                            <app-assessment-summary [summary]="completedSummary" />
                        </div>
                    </div>
                }
            </ng-template>
        </mat-tab>
        <mat-tab label="Email Activity">
            <app-assessment-email-history [assessmentEmails]="emails" [currentUser]="currentUser" />
        </mat-tab>
    </mat-tab-group>

    @if (!!assessment) {
        <mat-action-row exportRemovableAction *jhiHasNotAuthority="[Roles.ReadOnly]">
            <button mat-button visoButton="secondary" [matMenuTriggerFor]="speedDial">
                <mat-icon>arrow_drop_down</mat-icon>
                Assessment options
            </button>
            <mat-menu class="speed-dial" #speedDial="matMenu" xPosition="before">
                <div class="d-flex flex-column">
                    <ng-container *ngIf="waitingOnVendor">
                        <button
                            mat-menu-item
                            *ngIf="showSendReminderEmailButton"
                            data-pw="assessmentsHandler-inProgress-reminder"
                            (click)="sendReminderEmail.emit()"
                        >
                            Send Reminder Email
                        </button>
                        <ng-container *hideIfOrgUserIsNotBusinessOwner="businessOwner">
                            <button
                                mat-menu-item
                                *jhiHasAnyAuthority="[Roles.OrgAdmin, Roles.OrgUser]"
                                [disabled]="proceedWithAvailableDataButtonIsDisabled"
                                (click)="proceedWithAvailableData.emit()"
                            >
                                Proceed with available data
                            </button>
                            <button
                                mat-menu-item
                                class="cancel-assessment"
                                *jhiHasAnyAuthority="[Roles.OrgAdmin, Roles.OrgUser]"
                                toggleDisableOnActions
                                data-pw="assessmentsHandler-inProgress-cancel"
                                [disablingActions]="[AssessmentActions.CancelRequestLatestAssessmentRequest]"
                                [enablingActions]="[
                                    AssessmentActions.CancelRequestLatestAssessmentRequestSuccess,
                                    AssessmentActions.CancelRequestLatestAssessmentRequestFailed,
                                ]"
                                (click)="cancelAssessment.emit()"
                            >
                                Cancel
                            </button>
                        </ng-container>
                    </ng-container>
                    <a
                        mat-menu-item
                        *ngIf="showAuditorView && !!assessment"
                        data-pw="assessmentsHandler-inProgress-viewDetails"
                        [routerLink]="['/assessments/', assessmentId]"
                        >Auditor View
                    </a>
                </div>
            </mat-menu>
        </mat-action-row>
    }
</mat-expansion-panel>
