import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpErrorResponse } from '@angular/common/http';
import { NavigationStart, Router } from '@angular/router';
import { ApolloError } from '@apollo/client';
import { EMPTY, merge, of, timer } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { NotificationService, NotificationStatus } from '@entities/notification';
import { assessmentCollectionRoute } from '../../routes/assessment-collection/assessment-collection-common/route-constants';
import {
    getNewNotificationsSubscription,
    getNewNotificationsSubscriptionFailed,
    getNewNotificationsSubscriptionSuccess,
    markAllNotificationsAsDismissed,
    markAllNotificationsAsRead,
    markNotificationAsDismissed,
    notificationsQuerySuccess,
    notificationsStatusUpdatedSuccess,
    refreshNotifications,
    setLayout,
    setPageSpotlightTourType,
    setSpotlightTourStatus,
    startNotificationPollingFallback,
    startSpotlightTour,
    startSpotlightTourForCurrentPage,
} from './layout.actions';
import { LayoutType } from '@shared/model/layout-type';
import { ROUTER_NAVIGATED, RouterNavigationAction } from '@ngrx/router-store';
import { SPOTLIGHT_SUPPORTED_ROUTES } from '@shared/spotlight-tour/supported-routes';
import { getCurrentPageTourType, getSpotlightTourStatus } from './layout.selectors';
import { Store } from '@ngrx/store';
import { GuidedTourService } from 'ngx-guided-tour';
import { ToursByType } from '@shared/spotlight-tour/steps';
import { NOOP } from '@shared/redux/actions';

const assessmentArtifactUpdateUrl = '/assessment-artifact-update';
const assessmentRecertificationUrl = '/assessment-recertification';
const userRegistrationUrl = '/sign-up';
const activateAccountUrl = '/activate';

@Injectable()
export class LayoutEffects {
    startNotificationPollingFallback$ = createEffect(() =>
        this._actions$.pipe(
            ofType(startNotificationPollingFallback),
            switchMap(() => merge(timer(0, 10000)).pipe(switchMap(() => of(refreshNotifications())))),
        ),
    );

    refreshNotifications$ = createEffect(() =>
        this._actions$.pipe(
            ofType(refreshNotifications),
            switchMap(() =>
                this._notificationsService.findAllReadAndNewNotificationsForCurrentUser().pipe(
                    map((response) => {
                        return notificationsQuerySuccess({
                            results: response.edges.map((edge) => edge.node),
                            totalCount: response.totalCount,
                        });
                    }),
                    catchError((error: HttpErrorResponse) => EMPTY),
                ),
            ),
        ),
    );

    getNewNotificationsSubscription$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getNewNotificationsSubscription),
            switchMap(() =>
                this._notificationsService.onNewNotificationForCurrentUser().pipe(
                    map((response) =>
                        getNewNotificationsSubscriptionSuccess({
                            newNotification: response,
                        }),
                    ),
                    catchError((error: ApolloError) => {
                        return of(getNewNotificationsSubscriptionFailed({ error }));
                    }),
                ),
            ),
        ),
    );

    getNewNotificationsSubscriptionFailed$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getNewNotificationsSubscriptionFailed),
            map(() => startNotificationPollingFallback()),
        ),
    );

    markAllNotificationsAsRead$ = createEffect(() =>
        this._actions$.pipe(
            ofType(markAllNotificationsAsRead),
            switchMap(({ notificationIds }) =>
                this._notificationsService
                    .updateStatus(NotificationStatus.READ, {
                        recordIds: notificationIds,
                    })
                    .pipe(
                        map(() => notificationsStatusUpdatedSuccess()),
                        catchError((error: HttpErrorResponse) => EMPTY),
                    ),
            ),
        ),
    );

    markNotificationAsDismissed$ = createEffect(() =>
        this._actions$.pipe(
            ofType(markNotificationAsDismissed),
            map((action) => action.notifications),
            filter((notifications) => notifications?.length > 0),
            switchMap((notifications) =>
                this._notificationsService
                    .updateStatus(NotificationStatus.DISMISSED, {
                        recordIds: notifications.map((n) => n.id),
                    })
                    .pipe(
                        map(() => notificationsStatusUpdatedSuccess()),
                        catchError((error: HttpErrorResponse) => EMPTY),
                    ),
            ),
        ),
    );

    markAllNotificationsAsDismissed$ = createEffect(() =>
        this._actions$.pipe(
            ofType(markAllNotificationsAsDismissed),
            switchMap(() =>
                this._notificationsService.updateStatus(NotificationStatus.DISMISSED, { recordIds: [] }, true).pipe(
                    map(() => notificationsStatusUpdatedSuccess()),
                    catchError((error: HttpErrorResponse) => EMPTY),
                ),
            ),
        ),
    );

    setLayoutType$ = createEffect(() =>
        this._router.events.pipe(
            filter((event) => event instanceof NavigationStart),
            map((event: NavigationStart) => {
                if (
                    event.url.includes(`/${assessmentCollectionRoute}`) ||
                    event.url.includes(assessmentArtifactUpdateUrl) ||
                    event.url.includes(assessmentRecertificationUrl)
                ) {
                    return setLayout({ layout: LayoutType.Assessment });
                }
                if (event.url.includes(userRegistrationUrl) || event.url.includes(activateAccountUrl)) {
                    return setLayout({ layout: LayoutType.Blank });
                }
                return setLayout({ layout: LayoutType.Full });
            }),
        ),
    );

    updateSpotlightTourTypeForCurrentPage$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            map((action: RouterNavigationAction) => {
                // Map the current route to its route config as defined in the routing module.
                // IE: "/assessments/1234" -> "/assessments/:assessmentId"
                let currentRoute = action.payload.routerState.root;
                let route = '';
                while (!!currentRoute.firstChild) {
                    currentRoute = currentRoute.firstChild;
                    route += '/' + currentRoute.routeConfig.path;
                }
                return route[route.length - 1] == '/' ? route.substring(0, route.length - 1) : route;
            }),
            map((routeWithoutValues) =>
                setPageSpotlightTourType({ tourType: SPOTLIGHT_SUPPORTED_ROUTES[routeWithoutValues] }),
            ),
        ),
    );

    startSpotlightTour$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(startSpotlightTour),
                tap(({ tourType }) => this._guidedTourService.startTour(ToursByType[tourType])),
            ),
        { dispatch: false },
    );

    setSpotlightTourStatus$ = createEffect(() =>
        this._guidedTourService.guidedTourCurrentStepStream.pipe(
            withLatestFrom(this._store$.select(getSpotlightTourStatus)),
            map(([step, active]) => {
                if (!!step && !active) {
                    return setSpotlightTourStatus({ active: true });
                }
                if (!step && active) {
                    return setSpotlightTourStatus({ active: false });
                }
                return NOOP();
            }),
        ),
    );

    startSpotlightTourForCurrentPage$ = createEffect(() =>
        this._actions$.pipe(
            ofType(startSpotlightTourForCurrentPage),
            withLatestFrom(this._store$.select(getCurrentPageTourType)),
            map(([, tourType]) => startSpotlightTour({ tourType })),
        ),
    );

    constructor(
        private _store$: Store,
        private _actions$: Actions,
        private _router: Router,
        private _notificationsService: NotificationService,
        private _guidedTourService: GuidedTourService,
    ) {}
}
