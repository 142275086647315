import { Action, createReducer, on } from '@ngrx/store';
import { initialState, RelationshipsState } from './relationships-state';
import {
    clearAllFilters,
    clearVendorTierStats,
    getRelationshipCountsRequestSuccess,
    getVendorTierStatsRequestSuccess,
    setAdditionalFiltersState,
    setColumnsState,
} from './relationships.actions';

const reducer = createReducer(
    initialState,
    on(setColumnsState, (state, { results }) => ({
        ...state,
        columnsGroups: [...results],
    })),
    on(clearAllFilters, (state) => ({
        ...state,
        additionalFilters: null,
    })),
    on(setAdditionalFiltersState, (state, { filters }) => ({
        ...state,
        additionalFilters: { ...filters },
    })),
    on(getVendorTierStatsRequestSuccess, (state, { vendorTierStats }) => ({
        ...state,
        vendorTierStats: [...state.vendorTierStats, vendorTierStats],
    })),
    on(clearVendorTierStats, (state) => ({
        ...state,
        vendorTierStats: [],
    })),
    on(getRelationshipCountsRequestSuccess, (state, { relationshipCounts }) => ({
        ...state,
        relationshipCounts: {
            total: relationshipCounts.total,
            byTier: Object.fromEntries(relationshipCounts.byTier),
        },
    })),
);

export function relationshipsReducer(state: RelationshipsState = initialState, action: Action): RelationshipsState {
    return reducer(state, action);
}
