import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { RelationshipAdditionalFilters } from '../models/relationship-additional-filters.model';
import { RelationshipColumnGroup } from '../models/relationship-column.model';
import { AppState, initialState, RelationshipsState, relationshipsStateKey } from './relationships-state';
import { VendorTierStats } from '@entities/vendor-tier';
import { RelationshipCounts } from '../models/relationship.model';

export const getRelationshipsState: MemoizedSelector<AppState, RelationshipsState> =
    createFeatureSelector<RelationshipsState>(relationshipsStateKey);

export const getRelationshipsColumnGroups: MemoizedSelector<AppState, RelationshipColumnGroup[]> = createSelector(
    getRelationshipsState,
    (state) => state.columnsGroups || initialState.columnsGroups,
);

export const getRelationshipsColumnsInitialState: MemoizedSelector<AppState, RelationshipColumnGroup[]> =
    createSelector(getRelationshipsState, (state) => initialState.columnsGroups);

export const getRelationshipsAdditionalFiltersState: MemoizedSelector<AppState, RelationshipAdditionalFilters> =
    createSelector(getRelationshipsState, (state) => state.additionalFilters);

export const getVendorTierStats: MemoizedSelector<AppState, VendorTierStats[]> = createSelector(
    getRelationshipsState,
    (state) => state.vendorTierStats,
);

export const getRelationshipCounts: MemoizedSelector<AppState, RelationshipCounts> = createSelector(
    getRelationshipsState,
    (state) => ({
        total: state.relationshipCounts.total,
        byTier: new Map(Object.entries(state.relationshipCounts.byTier).map(([k, v]) => [+k, v])),
    }),
);
