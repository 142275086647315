import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxSelectModule } from 'ngx-select-ex';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { NgChartsModule } from 'ng2-charts';
import { ArchwizardModule } from '@viso-trust/angular-archwizard';
import { SharedModule } from '../../shared';
import { FileUploadModule } from '../../shared/file-upload';
import { QuillWrapperModule } from '../../shared/components/quill-wrapper/quill-wrapper.module';
import { requestPopupRoutes, requestRoutes } from './request.route';
import { ArtifactIntelligenceModule } from '../../shared/artifact-intelligence';
import { UploadArtifactsModule } from '../../shared/upload-artifacts';
import { UploadAttachmentsModule } from '../../shared/upload-attachments';
import { AssessmentComponentsModule } from '../../shared/assessment-components/assessment-components.module';
import { ReplacePipeModule } from '@shared/pipes/replace-pipe/replace-pipe.module';
import { PrimaryVendorContactService } from '../primary-vendor-contact';
import { RequestComponent } from './request.component';
import { effects } from './redux/effects';
import { CommentHandlerComponent } from './comments/comment-handler/comment-handler.component';
import { CreateCommentComponent } from './comments/create-comment/create-comment.component';
import { CommentListComponent } from './comments/comment-list/comment-list.component';
import { HeadButtonGroupComponent } from './head-button-group/head-button-group.component';
import { EditBusinessOwnerModalComponent } from './edit-business-owner/edit-business-owner-modal.component';
import { RelationshipDetailPageExportComponent } from './export/relationship-detail-page-export/relationship-detail-page-export.component';
import { HeaderComponent } from './header/header.component';
import { StatBoxesComponent } from './stat-boxes/stat-boxes.component';
import { RiskTimelineComponent } from './risk-timeline/risk-timeline.component';
import { AssessmentListComponent } from './assessments/assessment-list/assessment-list.component';
import { frameworkMappingsStateKey, iiqrResponsesStateKey, relationshipStateKey } from './redux/request.state';
import { frameworkMappingsReducer, iiqrResponsesReducer, relationshipReducer } from './redux/request.reducers';
import { RiskTimelineExportComponent } from './export/risk-timeline-export/risk-timeline-export.component';
import { ArtifactListExportComponent } from './export/artifact-list-export/artifact-list-export.component';
import { AttachmentListExportComponent } from './export/attachment-list-export/attachment-list-export.component';
import { RelationshipOnboardingModalComponent } from './relationship-tab/relationship-onboarding-modal/relationship-onboarding-modal.component';
import { AssessmentListExportComponent } from './export/assessment-list-export/assessment-list-export.component';
import { BusinessCaseComponent } from './relationship-tab/business-case/business-case.component';
import { DataTypesComponent } from './relationship-tab/data-types/data-types.component';
import { BusinessOwnerComponent } from './relationship-tab/business-owner/business-owner.component';
import { ThirdPartyContactComponent } from './relationship-tab/third-party-contact/third-party-contact.component';
import { SubscribersComponent } from './relationship-tab/subscribers/subscribers.component';
import { LifecycleManagementComponent } from './relationship-tab/lifecycle-management/lifecycle-management.component';
import { RelationshipResolver } from './relationship.resolver';
import { AssignBusinessOwnerModule } from '../business-owner/assign-business-owner.module';
import { RelationshipDetailsFormModalComponent } from './relationship-details-form-modal/relationship-details-form-modal.component';
import { AddSubscriberModalComponent } from './add-subscriber/add-subscriber-modal.component';
import { StartAssessmentComponent } from './assessments/start-assessment/start-assessment.component';
import {
    DisableStartAssessmentOnRulesDirective,
    DisableStartAssessmentWrapperComponent,
} from './directives/disable-start-assessment-on-rules.directive';
import { RelationshipTabComponent } from './relationship-tab/relationship-tab.component';
import { RelationshipDetailsComponent } from './relationship-tab/relationship-details/relationship-details.component';
import { VendorDetailsTabComponent } from './vendor-details-tab/vendor-details-tab.component';
import { TagsComponent } from './relationship-tab/tags/tags.component';
import { AttachmentsComponent } from './attachments/attachments.component';
import { RiskTimelineListComponent } from './risk-timeline-list/risk-timeline-list.component';
import { RelationshipContextModalComponent } from './relationship-context/relationship-context-modal/relationship-context-modal.component';
import { RelationshipContextModalHelperService } from './relationship-context/relationship-context-modal-helper.service';
import { DataTypesSelectionComponent } from './relationship-context/data-types-selection/data-types-selection.component';
import { StepHeaderWithRiskPreviewComponent } from './relationship-context/step-header-with-risk-preview/step-header-with-risk-preview.component';
import { BusinessCasesSelectionComponent } from './relationship-context/business-cases-selection/business-cases-selection.component';
import { InLineCalloutComponent } from './in-line-callout/in-line-callout.component';
import { RdpAiQuestionAnsweringComponent } from './rdp-ai-question-answering/rdp-ai-question-answering/rdp-ai-question-answering.component';
import { LifecycleManagementFormComponent } from './lifecycle-management/lifecycle-management-form/lifecycle-management-form.component';
import { LifecycleManagementModalComponent } from './lifecycle-management/lifecycle-management-modal/lifecycle-management-modal.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HideIfOrgUserIsNotBusinessOwnerModule } from '../../shared/hide-if-org-user-is-not-business-owner/hide-if-org-user-is-not-business-owner.module';
import {
    DisableOnboardOnRulesDirective,
    DisableOnboardWrapperComponent,
} from './directives/disable-onboard-on-rules.directive';
import { AssessmentListItemComponent } from './assessments/assessment-list-item/assessment-list-item.component';
import { DefaultNullValueDirective } from '../../shared/directives/default-null-value/default-null-value.directive';
import { MatChipsModule } from '@angular/material/chips';
import { ChipModule } from '../../shared/chip';
import { SensitivityMeterModule } from '../../shared/sensitivity-meter';
import { NgOptimizedImage } from '@angular/common';
import { VendorComponentsModule } from '../../shared/vendor-components/vendor-components.module';
import { AssessmentSummaryConfigDialogComponent } from './assessments/assessment-summary-config-dialog/assessment-summary-config-dialog.component';
import { RemediationDialogComponent } from './assessments/remediation-dialog/remediation-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { TimeAgoPipeModule } from '@shared/pipes/timeago-pipe/timeago-pipe.module';
import {
    FRAMEWORK_MAPPINGS_CONFIG_TOKEN,
    FRAMEWORK_MAPPINGS_LOCAL_STORAGE_KEY,
    FRAMEWORK_MAPPINGS_STORAGE_KEYS,
} from './framework-mappings.tokens';
import { getFrameworkMappingsConfig } from './framework-mappings.config';
import { ReviewRiskDialogComponent } from './assessments/review-risk-dialog/review-risk-dialog.component';
import { RiskOverrideComponent } from './risk-override/risk-override.component';
import { VisoPopoverModule } from '@shared/viso-popover/viso-popover.module';
import { ReversePipeModule } from '@shared/pipes/reverse/reverse-pipe.module';
import { DisclaimerModule } from '@shared/disclaimer';
import { SupplementalQuestionnairesComponent } from './relationship-tab/supplemental-questionnaires/supplemental-questionnaires.component';
import { SupplementalQuestionnairesDialogComponent } from './relationship-tab/supplemental-questionnaires/supplemental-questionnaires-dialog/supplemental-questionnaires-dialog.component';
import { SupplementalQuestionnairesConfigFormComponent } from './relationship-tab/supplemental-questionnaires/supplemental-questionnaires-dialog/supplemental-questionnaires-config-form/supplemental-questionnaires-config-form.component';
import { FollowupTypeSelectionComponent } from './assessments/followup-type-selection/followup-type-selection.component';
import { ReviewFollowupDialogComponent } from './assessments/review-followup-dialog/review-followup-dialog.component';
import { EditFollowupMethodDialog } from './assessments/edit-followup-dialog/edit-followup-method-dialog.component';
import { TruncateModule } from '@shared/truncate/truncate.module';
import { MoveTierComponent } from './relationship-tab/move-tier/move-tier.component';
import { MoveTierDialogComponent } from './relationship-tab/move-tier-dialog/move-tier-dialog.component';

const ENTITY_STATES = [...requestRoutes, ...requestPopupRoutes];

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(ENTITY_STATES),
        NgxSelectModule,
        NgxDatatableModule,
        NgbModule,
        NgChartsModule,
        FileUploadModule,
        QuillWrapperModule,
        StoreModule.forFeature(relationshipStateKey, relationshipReducer),
        StoreModule.forFeature(frameworkMappingsStateKey, frameworkMappingsReducer, FRAMEWORK_MAPPINGS_CONFIG_TOKEN),
        StoreModule.forFeature(iiqrResponsesStateKey, iiqrResponsesReducer),
        EffectsModule.forFeature(effects),
        AssignBusinessOwnerModule,
        PopoverModule,
        ArtifactIntelligenceModule,
        UploadArtifactsModule,
        AssessmentComponentsModule,
        UploadAttachmentsModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        ArchwizardModule,
        MatRadioModule,
        MatTooltipModule,
        HideIfOrgUserIsNotBusinessOwnerModule,
        MatTabsModule,
        MatCardModule,
        MatExpansionModule,
        ReplacePipeModule,
        MatMenuModule,
        MatFormFieldModule,
        MatInputModule,
        MatListModule,
        MatChipsModule,
        ChipModule,
        SensitivityMeterModule,
        NgOptimizedImage,
        VendorComponentsModule,
        MatDialogModule,
        MatOptionModule,
        MatSelectModule,
        TimeAgoPipeModule,
        VisoPopoverModule,
        ReversePipeModule,
        DisclaimerModule,
        TruncateModule,
    ],
    declarations: [
        RequestComponent,
        CommentHandlerComponent,
        CreateCommentComponent,
        CommentListComponent,
        HeadButtonGroupComponent,
        BusinessOwnerComponent,
        EditBusinessOwnerModalComponent,
        AddSubscriberModalComponent,
        RelationshipDetailPageExportComponent,
        HeaderComponent,
        StatBoxesComponent,
        RiskTimelineComponent,
        AssessmentListComponent,
        RiskTimelineExportComponent,
        ArtifactListExportComponent,
        AssessmentListExportComponent,
        RelationshipOnboardingModalComponent,
        BusinessCaseComponent,
        DataTypesComponent,
        ThirdPartyContactComponent,
        SubscribersComponent,
        LifecycleManagementComponent,
        RelationshipDetailsFormModalComponent,
        StartAssessmentComponent,
        DisableStartAssessmentOnRulesDirective,
        DisableStartAssessmentWrapperComponent,
        DisableOnboardOnRulesDirective,
        DisableOnboardWrapperComponent,
        RelationshipTabComponent,
        RelationshipDetailsComponent,
        VendorDetailsTabComponent,
        TagsComponent,
        SupplementalQuestionnairesComponent,
        AttachmentsComponent,
        AttachmentListExportComponent,
        RiskTimelineListComponent,
        RelationshipContextModalComponent,
        DataTypesSelectionComponent,
        StepHeaderWithRiskPreviewComponent,
        BusinessCasesSelectionComponent,
        InLineCalloutComponent,
        RdpAiQuestionAnsweringComponent,
        LifecycleManagementFormComponent,
        LifecycleManagementModalComponent,
        AssessmentListItemComponent,
        DefaultNullValueDirective,
        AssessmentSummaryConfigDialogComponent,
        RemediationDialogComponent,
        ReviewRiskDialogComponent,
        RiskOverrideComponent,
        FollowupTypeSelectionComponent,
        ReviewFollowupDialogComponent,
        EditFollowupMethodDialog,
        SupplementalQuestionnairesDialogComponent,
        SupplementalQuestionnairesConfigFormComponent,
        MoveTierComponent,
        MoveTierDialogComponent,
    ],
    providers: [
        PrimaryVendorContactService,
        RelationshipResolver,
        RelationshipContextModalHelperService,
        { provide: FRAMEWORK_MAPPINGS_STORAGE_KEYS, useValue: [] },
        { provide: FRAMEWORK_MAPPINGS_LOCAL_STORAGE_KEY, useValue: 'storage__' + frameworkMappingsStateKey },
        {
            provide: FRAMEWORK_MAPPINGS_CONFIG_TOKEN,
            deps: [FRAMEWORK_MAPPINGS_LOCAL_STORAGE_KEY, FRAMEWORK_MAPPINGS_STORAGE_KEYS],
            useFactory: getFrameworkMappingsConfig,
        },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RequestModule {}
