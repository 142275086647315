import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, first, of, tap } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import {
    cancelCreateSupplementalQuestionnaireDialog,
    closeCreateSupplementalQuestionnaireDialog,
    createSupplementalQuestionnaireRequest,
    createSupplementalQuestionnaireRequestFailed,
    createSupplementalQuestionnaireRequestSuccess,
    deleteSupplementalQuestionnaireRequest,
    deleteSupplementalQuestionnaireRequestFailed,
    deleteSupplementalQuestionnaireRequestSuccess,
    getSupplementalQuestionnairesRequest,
    getSupplementalQuestionnairesRequestFailed,
    getSupplementalQuestionnairesRequestSuccess,
    importSupplementalQuestionnaireRequest,
    importSupplementalQuestionnaireRequestCancelled,
    importSupplementalQuestionnaireRequestCancelledFailed,
    importSupplementalQuestionnaireRequestCancelledSuccess,
    importSupplementalQuestionnaireRequestFailed,
    importSupplementalQuestionnaireRequestProgress,
    importSupplementalQuestionnaireRequestSuccess,
    onWhenAddingImportFileFailed,
    openCreateSupplementalQuestionnaireDialog,
    updateSupplementalQuestionnaireRequest,
    updateSupplementalQuestionnaireRequestFailed,
    updateSupplementalQuestionnaireRequestSuccess,
    uploadSupplementalQuestionnairesRequest,
    uploadSupplementalQuestionnairesRequestFailed,
    uploadSupplementalQuestionnairesRequestSuccess,
} from './questionnaire-management.actions';
import { MatDialogWrapperService } from '@shared/modal/mat-dialog-wrapper.service';
import {
    SupplementalQuestionnaireDialogComponent,
    SupplementalQuestionnaireDialogStep,
    SupplementalQuestionnaireDialogStepIndex,
} from '../components/supplemental-questionnaire-dialog/supplemental-questionnaire-dialog.component';
import { MatConfirmDialogService } from '@shared/components/mat-confirm-dialog/mat-confirm-dialog.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { BulkImportService } from '../../../bulk-import/bulk-import.service';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';
import { ROUTER_NAVIGATED, RouterNavigationAction } from '@ngrx/router-store';
import { ActivatedRouteSnapshot } from '@angular/router';
import { SupplementalQuestionnaireService } from '@entities/supplemental-questionnaire';

@Injectable()
export class QuestionnaireManagementEffects {
    getOrgQuestionnairesRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getSupplementalQuestionnairesRequest),
            switchMap(({ pageable }) =>
                this._supplementalQuestionnaireService.findAllSupplementalQuestionnairesForCurrentUser(pageable).pipe(
                    map((response) =>
                        getSupplementalQuestionnairesRequestSuccess({
                            questionnaires: response.body,
                            headers: response.headers,
                        }),
                    ),
                    catchError((error) => of(getSupplementalQuestionnairesRequestFailed(error))),
                ),
            ),
        ),
    );

    uploadOrgQuestionnairesRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(uploadSupplementalQuestionnairesRequest),
            switchMap(({ fileRequests: requests }) =>
                this._supplementalQuestionnaireService.uploadSupplementalQuestionnaires(requests).pipe(
                    map(() => uploadSupplementalQuestionnairesRequestSuccess()),
                    catchError((error) => of(uploadSupplementalQuestionnairesRequestFailed({ error }))),
                ),
            ),
        ),
    );

    deleteOrgQuestionnaireRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(deleteSupplementalQuestionnaireRequest),
            switchMap(({ questionnaireId }) =>
                this._confirmDialogService
                    .confirm({
                        title: 'Are you sure you want to delete this questionnaire?',
                        message:
                            'Deleting this questionnaire will remove it from all future assessments, but will not delete responses that have already been submitted. This action cannot be undone.',
                        cancelLabel: 'Cancel',
                        cancelButtonStyle: 'text',
                        cancelButtonColor: 'primary',
                        confirmLabel: 'Yes, delete',
                        confirmButtonColor: 'warn',
                    })
                    .pipe(
                        filter((result) => result),
                        switchMap(() =>
                            this._supplementalQuestionnaireService
                                .deleteSupplementalQuestionnaire(questionnaireId)
                                .pipe(
                                    map(() => deleteSupplementalQuestionnaireRequestSuccess()),
                                    catchError((error) => of(deleteSupplementalQuestionnaireRequestFailed(error))),
                                ),
                        ),
                    ),
            ),
        ),
    );

    openCreateSupplementalQuestionnaireDialog$ = createEffect(() =>
        this._actions$.pipe(
            ofType(openCreateSupplementalQuestionnaireDialog),
            switchMap(({ questionnaireStep, supplementalQuestionnaireFormData, questionnaireToEdit }) =>
                this._dialogService
                    .open<SupplementalQuestionnaireDialogComponent>(SupplementalQuestionnaireDialogComponent, {
                        config: {
                            disableClose: true,
                            maxWidth: 1200,
                            height: '85vh',
                        },
                        inputs: {
                            questionnaireToEdit:
                                questionnaireStep === SupplementalQuestionnaireDialogStep.EDIT
                                    ? questionnaireToEdit
                                    : null,
                            existingFormData: supplementalQuestionnaireFormData,
                            questionnaireStep,
                            currentStepperIndex:
                                questionnaireStep === SupplementalQuestionnaireDialogStep.IMPORT
                                    ? SupplementalQuestionnaireDialogStepIndex.IMPORT
                                    : SupplementalQuestionnaireDialogStepIndex.MODIFY,
                        },
                    })
                    .afterClosed()
                    .pipe(
                        filter(Boolean),
                        map(({ requestPayload, questionnaireStep }) =>
                            questionnaireStep === SupplementalQuestionnaireDialogStep.EDIT
                                ? updateSupplementalQuestionnaireRequest({ updateRequest: requestPayload })
                                : createSupplementalQuestionnaireRequest({ createRequest: requestPayload }),
                        ),
                    ),
            ),
        ),
    );

    createSupplementalQuestionnaireRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(createSupplementalQuestionnaireRequest),
            switchMap(({ createRequest }) =>
                this._supplementalQuestionnaireService.createSupplementalQuestionnaire(createRequest).pipe(
                    map(() => createSupplementalQuestionnaireRequestSuccess()),
                    catchError((error) => of(createSupplementalQuestionnaireRequestFailed({ error }))),
                ),
            ),
        ),
    );

    updateSupplementalQuestionnaireRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(updateSupplementalQuestionnaireRequest),
            switchMap(({ updateRequest }) =>
                this._supplementalQuestionnaireService.updateSupplementalQuestionnaire(updateRequest).pipe(
                    map(() => updateSupplementalQuestionnaireRequestSuccess()),
                    catchError((error) => of(updateSupplementalQuestionnaireRequestFailed({ error }))),
                ),
            ),
        ),
    );

    cancelCreateSupplementalQuestionnaireDialog$ = createEffect(() =>
        this._actions$.pipe(
            ofType(cancelCreateSupplementalQuestionnaireDialog),
            switchMap(({ supplementalQuestionnaireFormData, importJobId, questionnaireStep }) =>
                this._confirmDialogService
                    .confirm({
                        title: 'Are you sure you want to discard your changes?',
                        message: 'This action cannot be undone.',
                        cancelLabel: 'Cancel',
                        cancelButtonStyle: 'text',
                        cancelButtonColor: 'primary',
                        confirmLabel: 'Yes, discard',
                        confirmButtonColor: 'warn',
                    })
                    .pipe(
                        first(),
                        map((reopenOrClose: boolean) =>
                            reopenOrClose
                                ? closeCreateSupplementalQuestionnaireDialog({ importJobId })
                                : openCreateSupplementalQuestionnaireDialog({
                                      questionnaireStep,
                                      supplementalQuestionnaireFormData,
                                  }),
                        ),
                    ),
            ),
        ),
    );

    cancelQuestionnaireImportRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(importSupplementalQuestionnaireRequestCancelled, closeCreateSupplementalQuestionnaireDialog),
            filter(({ importJobId }) => !!importJobId),
            switchMap(({ importJobId }) =>
                this._bulkImportService.cancelQuestionnaireImport(importJobId).pipe(
                    map(() => importSupplementalQuestionnaireRequestCancelledSuccess()),
                    catchError((error) => of(importSupplementalQuestionnaireRequestCancelledFailed({ error }))),
                ),
            ),
        ),
    );

    importSupplementalQuestionnaireRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(importSupplementalQuestionnaireRequest),
            switchMap(({ file }) =>
                this._bulkImportService.importQuestionnaire(file).pipe(
                    mergeMap((event: HttpEvent<any>) => {
                        switch (event.type) {
                            case HttpEventType.UploadProgress:
                                let percent = Math.round(100 * (event.loaded / event.total));
                                if (percent === 100) {
                                    percent = 99;
                                }
                                return of(importSupplementalQuestionnaireRequestProgress({ percent }));
                            case HttpEventType.Response:
                                return [
                                    importSupplementalQuestionnaireRequestProgress({ percent: 100 }),
                                    importSupplementalQuestionnaireRequestSuccess({ importReport: event.body }),
                                ];
                            default:
                                return EMPTY;
                        }
                    }),
                    catchError(() => of(importSupplementalQuestionnaireRequestFailed())),
                    takeUntil(this._actions$.pipe(ofType(importSupplementalQuestionnaireRequestCancelled))),
                ),
            ),
        ),
    );

    onWhenAddingImportFileFailed$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(onWhenAddingImportFileFailed),
                tap(({ message }) => this._snackbarService.error(message)),
            ),
        { dispatch: false },
    );

    importSupplementalQuestionnaireRequestFailed$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(importSupplementalQuestionnaireRequestFailed),
                tap(() => this._snackbarService.error('An error occurred when uploading the csv.')),
            ),
        { dispatch: false },
    );

    openEditSupplementalQuestionnaireDialog$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            map((action: RouterNavigationAction) =>
                action.payload.routerState.root.children.find((route) => route.outlet === 'popup'),
            ),
            distinctUntilChanged((prev, next) => prev?.routeConfig.path === next?.routeConfig.path),
            filter<ActivatedRouteSnapshot>(
                (route) => !!route && route.routeConfig.path === 'questionnaire/:supplementalQuestionnaireId/edit',
            ),
            map((route) =>
                openCreateSupplementalQuestionnaireDialog({
                    questionnaireStep: SupplementalQuestionnaireDialogStep.EDIT,
                    questionnaireToEdit: {
                        id: route.data.supplementalQuestionnaire?.id,
                        questionnaireName: route.data.supplementalQuestionnaire?.questionnaireName,
                        questions: route.data.supplementalQuestionnaire?.questions,
                        enabled: route.data.supplementalQuestionnaire?.enabled,
                    },
                }),
            ),
        ),
    );

    createSupplementalQuestionnaireRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(createSupplementalQuestionnaireRequestSuccess),
                tap(() => this._snackbarService.success('Supplemental questionnaire created successfully.')),
            ),
        { dispatch: false },
    );

    updateSupplementalQuestionnaireRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(updateSupplementalQuestionnaireRequestSuccess),
                tap(() => this._snackbarService.success('Supplemental questionnaire updated successfully.')),
            ),
        { dispatch: false },
    );

    deleteSupplementalQuestionnaireRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(deleteSupplementalQuestionnaireRequestSuccess),
                tap(() => this._snackbarService.success('Supplemental questionnaire deleted.')),
            ),
        { dispatch: false },
    );

    constructor(
        private _actions$: Actions,
        private _supplementalQuestionnaireService: SupplementalQuestionnaireService,
        private _confirmDialogService: MatConfirmDialogService,
        private _dialogService: MatDialogWrapperService,
        private _bulkImportService: BulkImportService,
        private _snackbarService: SnackbarService,
    ) {}
}
