<div class="row">
    <div class="col-md-12">
        <mat-progress-bar *ngIf="loadingRiskAdvisories" mode="indeterminate"></mat-progress-bar>
        <table
            mat-table
            [dataSource]="dataSource"
            matSort
            matSortDisableClear
            matSortActive="publishedDate"
            matSortDirection="desc"
            matSortStart="desc"
            class="viso-table"
            [trackBy]="trackByRiskAdvisoryId"
            (matSortChange)="onSortData($event)"
        >
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="title">Title</th>
                <td mat-cell *matCellDef="let row" class="d-flex align-items-center">
                    <button color="warn" mat-icon-button *ngIf="canEdit" (click)="onDeleteRiskAdvisory(row)">
                        <mat-icon>cancel</mat-icon>
                    </button>
                    <button
                        mat-button
                        visoButton="link"
                        (click)="canEdit ? openAdvisoryDialogEdit(row) : openDetailsDialog(row)"
                        class="text-primary"
                    >
                        {{ row.title }}
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="publishedDate">Date</th>
                <td mat-cell *matCellDef="let row">
                    {{ row.publishedDate | date: 'mediumDate' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="type">Type</th>
                <td mat-cell *matCellDef="let row">
                    {{ RiskAdvisoryTypeLabels[row.type] }}
                </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="description">Description</th>
                <td mat-cell *matCellDef="let row">
                    <div class="description-container" [innerHTML]="row.description || '' | safeHtml"></div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator
            (page)="setCurrentPage($event)"
            [pageIndex]="currentPage"
            [length]="totalRiskAdvisories"
            [pageSize]="itemsPerPage"
            [pageSizeOptions]="[10, 20, 30]"
            [showFirstLastButtons]="true"
            aria-label="Select page of Risk Advisories"
        ></mat-paginator>
    </div>
</div>
