import { GuidedTour } from 'ngx-guided-tour';
import { relationshipsListTour, vendorTieringTour, welcomeTour } from '@shared/spotlight-tour/tours';

export enum SpotlightTourType {
    WELCOME = 'WELCOME',
    RLP = 'RLP',
    VENDOR_TIERING = 'VENDOR_TIERING',
}

export const ToursByType: Record<SpotlightTourType, GuidedTour> = {
    WELCOME: welcomeTour(),
    RLP: relationshipsListTour(),
    VENDOR_TIERING: vendorTieringTour(),
};
