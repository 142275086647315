<form [formGroup]="createSupplementalQuestionnaireFormGroup" class="h-100">
    <div class="row h-100">
        <div class="col-4">
            <div class="row mb-3">
                <div class="col">
                    <h3 class="text-normal mt-3">
                        @if (isEditing) {
                            Edit questionnaire
                        } @else {
                            Create questionnaire
                        }
                    </h3>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <mat-form-field class="w-100">
                        <mat-label>Questionnaire name</mat-label>
                        <input
                            type="text"
                            matInput
                            formControlName="questionnaireName"
                            placeholder="Enter questionnaire name"
                        />
                    </mat-form-field>
                    <p class="text-sm text-light ms-2">
                        Add a descriptive name. This will be visible to your vendors during an assessment.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col d-inline-flex">
                    <mat-slide-toggle color="primary" formControlName="enabled" />
                    <div class="ms-2">
                        <p class="mb-0">Enable for every relationship</p>
                        <p class="text-sm text-light">These defaults can be changed in the relationship settings.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-auto">
            <mat-divider class="h-100 d-flex" [vertical]="true"></mat-divider>
        </div>
        <div class="col">
            <div class="row mt-3">
                <div class="col">
                    <div class="d-flex justify-content-between mb-3 mx-3 fw-bold">
                        <div>Questions</div>
                        <div [ngClass]="{ 'text-error': questionsLeft < 5 }">
                            {{ questionsLeft }} remaining in license
                        </div>
                    </div>
                </div>
            </div>

            <div class="table-container">
                <table mat-table [dataSource]="dataSource" class="viso-table">
                    <ng-container matColumnDef="question">
                        <th mat-header-cell *matHeaderCellDef>Question</th>
                        <td mat-cell *matCellDef="let row">
                            @if (row.controls.editing.value) {
                                <mat-form-field class="w-100 editing-background">
                                    <input
                                        type="text"
                                        matInput
                                        [formControl]="row.controls.question"
                                        placeholder="Type your question"
                                        (keydown.enter)="toggleEditMode(row)"
                                    />
                                </mat-form-field>
                            } @else {
                                {{ row.controls.question.value }}
                            }
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actions" stickyEnd [sticky]="true">
                        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                        <td mat-cell *matCellDef="let row; let i = index" class="table-actions">
                            @if (row.controls.editing.value) {
                                <div class="d-flex">
                                    <button mat-icon-button type="button" (click)="toggleEditMode(row)">
                                        <mat-icon>check</mat-icon>
                                    </button>
                                    <button mat-icon-button type="button" (click)="removeQuestion(row)">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </div>
                            } @else {
                                <div class="float-end">
                                    <button mat-icon-button type="button" [matMenuTriggerFor]="menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item type="button" (click)="toggleEditMode(row)">Edit</button>
                                        <button mat-menu-item type="button" (click)="removeQuestion(row)">
                                            Delete
                                        </button>
                                    </mat-menu>
                                </div>
                            }
                        </td>
                    </ng-container>

                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </div>

            <div class="d-flex justify-content-between">
                <button
                    type="button"
                    mat-button
                    class="mt-2"
                    color="primary"
                    (click)="addNewQuestion()"
                    [disabled]="cannotAddNewQuestion"
                >
                    <mat-icon>add</mat-icon>
                    Add another question
                </button>
                <mat-paginator
                    [length]="dataSource.data?.length"
                    [pageSize]="itemsPerPage"
                    [pageSizeOptions]="[10, 20, 30]"
                    aria-label="Select page of questions"
                >
                </mat-paginator>
            </div>
        </div>
    </div>
</form>
