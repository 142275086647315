import {
    cancelRequestLatestAssessmentRequestSuccess,
    startAssessmentRequestSuccess,
} from '../../request/redux/actions/assessments.actions';
import {
    cancelAssessmentsForRelationshipsRequestSuccess,
    sendAssessmentsForRelationshipsRequestSuccess,
    updateVendorTierDescriptionRequestSuccess,
    updateVendorTiersCountRequestSuccess,
} from '../../relationships/redux/relationships.actions';
import { cancelAssessmentRequestSuccess } from '../../assessment-details/assessment-details-page/redux/assessment-details.actions';
import { onboardRelationshipSuccess } from '../../request/redux/actions/onboard.actions';
import { createRelationshipRequestSuccess } from '../../add-relationship/redux/add-relationship.actions';
import { markRequestAsNotOnboardedRequestSuccess } from '../../request/redux/actions/mark.actions';
import { updateVisoUserMetadataSuccess } from './session.actions';

export const currentUserRefreshActions = [
    startAssessmentRequestSuccess,
    sendAssessmentsForRelationshipsRequestSuccess,
    cancelAssessmentsForRelationshipsRequestSuccess,
    cancelAssessmentRequestSuccess,
    cancelRequestLatestAssessmentRequestSuccess,
    onboardRelationshipSuccess,
    createRelationshipRequestSuccess,
    markRequestAsNotOnboardedRequestSuccess,
    updateVisoUserMetadataSuccess,
    updateVendorTiersCountRequestSuccess,
    updateVendorTierDescriptionRequestSuccess,
];
