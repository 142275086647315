import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { PrimaryVendorContact } from '../../primary-vendor-contact';
import {
    AppState,
    frameworkMappingsStateKey,
    iiqrResponsesStateKey,
    LastInteractiveIqrResponsesByRelationshipState,
    RelationshipFrameworkMappingsState,
    RelationshipState,
    relationshipStateKey,
} from './request.state';
import { SuggestedContact } from '@entities/relationship';
import { VisoUser } from '@entities/viso-user';
import { OrgAssessmentSummarySections } from '../../../admin/client-profile/client-profile.model';
import { FrameworkMapping } from '@entities/framework/models/framework-mapping.model';
import { Risk, RiskAssessment } from '@entities/risk-assessment';
import { isAssessmentInProgress } from '@shared/utils/assessment-utils.service';
import { BusinessUnit } from '@entities/business-unit';
import { DataType } from '@entities/data-type';
import { ControlDomain } from '@entities/control-domain';
import { getControls } from '../../controls/redux/controls.selectors';
import { getUserAccount } from '../../session/redux/session.selectors';
import { SupplementalQuestionnaireConfig } from '@entities/org';
import { VendorTier } from '@entities/vendor-tier';

export const getRelationshipState: MemoizedSelector<AppState, RelationshipState> =
    createFeatureSelector<RelationshipState>(relationshipStateKey);

export const getRelationshipId: MemoizedSelector<AppState, number> = createSelector(
    getRelationshipState,
    (state) => state.relationshipId,
);

export const getRelationshipPrimaryVendorContact: MemoizedSelector<AppState, PrimaryVendorContact> = createSelector(
    getRelationshipState,
    (state) => state.primaryVendorContact,
);

export const getRelationshipSuggestedContacts: MemoizedSelector<AppState, SuggestedContact[]> = createSelector(
    getRelationshipState,
    (state) => state.suggestedVendorContacts,
);

export const getMappedSuggestedContacts: MemoizedSelector<AppState, { id: SuggestedContact; text: string }[]> =
    createSelector(getRelationshipState, (suggestedContacts) =>
        suggestedContacts.suggestedVendorContacts.map((contact) => ({
            id: contact,
            text: `${contact.firstName} ${contact.lastName} • ${contact.email}`,
        })),
    );

export const getRelationshipAssessmentSummaryConfig: MemoizedSelector<AppState, OrgAssessmentSummarySections[]> =
    createSelector(getRelationshipState, (state) => state.assessmentSummaryConfig);

export const getRelationshipArtifactSupersession: MemoizedSelector<AppState, Map<number, number>> = createSelector(
    getRelationshipState,
    (state) => state.artifactSupersession,
);

export const getRelationshipBusinessOwner: MemoizedSelector<AppState, VisoUser> = createSelector(
    getRelationshipState,
    (state) => state.businessOwner,
);

export const getRelationshipSubscribers: MemoizedSelector<AppState, VisoUser[]> = createSelector(
    getRelationshipState,
    (state) => state.subscribers,
);

export const getIiqrResponsesState: MemoizedSelector<AppState, LastInteractiveIqrResponsesByRelationshipState> =
    createFeatureSelector<LastInteractiveIqrResponsesByRelationshipState>(iiqrResponsesStateKey);

export const getLastIiqrResponseByRelationshipId = (relationshipId: number) =>
    createSelector(getIiqrResponsesState, (state) => state[relationshipId]);

export const getRelationshipVendorName: MemoizedSelector<AppState, string> = createSelector(
    getRelationshipState,
    (state) => state.vendorName,
);

export const getFrameworkMappingsState: MemoizedSelector<AppState, RelationshipFrameworkMappingsState> =
    createFeatureSelector<RelationshipFrameworkMappingsState>(frameworkMappingsStateKey);

export const getFrameworkMappingsByRelationshipId = (relationshipId: number) =>
    createSelector(getFrameworkMappingsState, (state) => state[relationshipId]);

export const getFrameworkMappings: MemoizedSelector<AppState, FrameworkMapping> = createSelector(
    getFrameworkMappingsState,
    (state) => state.mappings,
);

export const getIsRiskAccepted: MemoizedSelector<AppState, boolean> = createSelector(
    getRelationshipState,
    (state) => state.riskAccepted,
);

export const getRelationshipLatestRiskAssessment: MemoizedSelector<AppState, RiskAssessment> = createSelector(
    getRelationshipState,
    (state) => state.latestRiskAssessment,
);

export const getRelationshipRiskOverrideLevel: MemoizedSelector<AppState, Risk> = createSelector(
    getRelationshipState,
    (state) => state.riskOverride.riskLevel,
);

export const getRelationshipRiskOverrideLastAppliedByFullName: MemoizedSelector<AppState, string> = createSelector(
    getRelationshipState,
    (state) => `${state.riskOverride.lastAppliedByFirstName} ${state.riskOverride.lastAppliedByLastName}`,
);

export const getRelationshipRiskOverrideLastAppliedDate: MemoizedSelector<AppState, Date> = createSelector(
    getRelationshipState,
    (state) => state.riskOverride.lastAppliedDate,
);

export const getRelationshipLatestAssessmentInProgress: MemoizedSelector<AppState, boolean> = createSelector(
    getRelationshipState,
    (state) => isAssessmentInProgress(state.assessments?.latestAssessment?.status),
);

export const getRelationshipClientContacts: MemoizedSelector<AppState, VisoUser[]> = createSelector(
    getRelationshipState,
    (state) => state.clientContacts,
);

export const getRelationshipClientBusinessUnits: MemoizedSelector<AppState, BusinessUnit[]> = createSelector(
    getRelationshipState,
    (state) => state.businessUnits,
);

export const getRelationshipClientDomains: MemoizedSelector<AppState, string[]> = createSelector(
    getRelationshipState,
    (state) => state.clientDomains,
);

export const getRelationshipClientId: MemoizedSelector<AppState, number> = createSelector(
    getRelationshipState,
    (state) => state.clientId,
);

export const getRelationshipSelectedDataTypes: MemoizedSelector<AppState, DataType[]> = createSelector(
    getRelationshipState,
    (state) => state.dataTypes,
);

export const getControlDomainsRequiringFollowup: MemoizedSelector<AppState, ControlDomain[]> = createSelector(
    getRelationshipLatestRiskAssessment,
    getControls,
    (latestRiskAssessment, controlDomains) =>
        controlDomains.filter((cd) =>
            latestRiskAssessment?.controlDomainAssessments?.some(
                (cda) => cda.controlDomainId === cd.id && cda.followupNeeded,
            ),
        ),
);

export const getRemainingConciergeAssessments: MemoizedSelector<AppState, number> = createSelector(
    getUserAccount,
    (currentUser) =>
        currentUser?.clientLicense?.maxConciergeAssessments == -1
            ? -1
            : currentUser?.clientLicense?.maxConciergeAssessments -
              currentUser?.clientLicense?.conciergeAssessmentCount,
);

export const getSupplementalQuestionnaireConfig: MemoizedSelector<
    AppState,
    { usingOrgDefaults: boolean; questionnaires: SupplementalQuestionnaireConfig[] }
> = createSelector(getRelationshipState, (state) => state.supplementalQuestionnaire);

export const getRelationshipVendorTier: MemoizedSelector<AppState, VendorTier> = createSelector(
    getRelationshipState,
    (state) => state.vendorTier,
);
