<div mat-dialog-title>
    Edit {{ tierName }} description
    <button mat-icon-button [mat-dialog-close]="undefined">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <mat-form-field class="w-100">
        <textarea
            [formControl]="newDescriptionControl"
            matInput
            placeholder="Enter a short description"
            maxlength="1000"
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
        ></textarea>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button type="button" [mat-dialog-close]="undefined">Cancel</button>
    <button mat-flat-button color="primary" type="button" [mat-dialog-close]="newDescriptionControl.value">Save</button>
</mat-dialog-actions>
