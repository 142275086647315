import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { OrgSizeLabels, OrgUrlType } from '@entities/org';
import {
    ComplianceStandard,
    ComplianceStandardLabels,
    LeadershipContactView,
    VendorDetailsResponse,
    TrustCenterType,
} from '../../../routes/request/models/vendor-details.model';
import { IMAGE_FALLBACK_BASE64 } from '../../image-fallback/image-fallback.constants';
import { VisoUserRole } from '@entities/viso-user';
import { NetworkExposureRiskAdvisory, RiskAdvisory } from '@entities/risk-advisory';
import { SortDirection } from '@angular/material/sort';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface VendorDetailsUrl {
    name: string;
    url: string;
    type: OrgUrlType;
}

@Component({
    selector: 'app-vendor-details',
    templateUrl: './vendor-details.component.html',
    styleUrls: ['./vendor-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorDetailsComponent {
    @Input({ required: true })
    set vendorDetails(value: VendorDetailsResponse) {
        this.vendorDetails$.next(value);
    }

    @Input({ required: true })
    riskAdvisories!: NetworkExposureRiskAdvisory[];

    @Input({ required: true })
    totalRiskAdvisories!: number;

    @Input({ required: true })
    showRefreshDetailsButton!: boolean;

    @Input({ required: true })
    refreshingVendorDetails!: boolean;

    @Input({ required: true })
    userCanEdit!: boolean;

    @Output()
    onEditClicked = new EventEmitter<void>();

    @Output()
    onRefreshDetailsClicked = new EventEmitter<void>();

    @Output() onRiskAdvisorySortChange: EventEmitter<{ column: string; direction: SortDirection }> = new EventEmitter();

    @Output() onRiskAdvisoryPageChange: EventEmitter<{ page: number; pageSize: number }> = new EventEmitter();

    @Output() deleteRiskAdvisory: EventEmitter<{ riskAdvisory: RiskAdvisory }> = new EventEmitter();

    readonly trustCenterLinkName: string = 'Trust Center';

    Roles = VisoUserRole;
    OrgUrlType = OrgUrlType;
    ComplianceStandard = ComplianceStandard;
    ComplianceStandardLabels = ComplianceStandardLabels;

    complianceStandardsFullSize = [
        ComplianceStandard.ISO27001,
        ComplianceStandard.ISO27017,
        ComplianceStandard.ISO27018,
        ComplianceStandard.ISO27701,
        ComplianceStandard.GDPR,
    ];

    vendorDetails$ = new BehaviorSubject<VendorDetailsResponse>(null);

    links$: Observable<VendorDetailsUrl[]> = this.vendorDetails$.pipe(
        map((vendorDetails) => {
            if (!vendorDetails || !vendorDetails.urls) {
                return [];
            }
            return [
                { name: 'Website', url: this.homepage, type: null },
                {
                    name: 'Privacy Policy',
                    url: vendorDetails?.urls[OrgUrlType.PRIVACY_POLICY],
                    type: OrgUrlType.PRIVACY_POLICY,
                },
                {
                    name: 'Status Page',
                    url: vendorDetails?.urls[OrgUrlType.STATUS_PAGE],
                    type: OrgUrlType.STATUS_PAGE,
                },
                {
                    name: this.trustCenterLinkName,
                    url: vendorDetails?.trustCenter?.url,
                    type: null,
                },
                {
                    name: 'Compliance Standards (1)',
                    url: vendorDetails?.urls[OrgUrlType.COMPLIANCE_STANDARDS_1],
                    type: OrgUrlType.COMPLIANCE_STANDARDS_1,
                },
                {
                    name: 'Compliance Standards (2)',
                    url: vendorDetails?.urls[OrgUrlType.COMPLIANCE_STANDARDS_2],
                    type: OrgUrlType.COMPLIANCE_STANDARDS_1,
                },
                {
                    name: 'Testimonials',
                    url: vendorDetails?.urls[OrgUrlType.CUSTOMER_LIST],
                    type: OrgUrlType.CUSTOMER_LIST,
                },
                {
                    name: 'Regulatory Filings',
                    url: vendorDetails?.urls[OrgUrlType.REGULATORY_FILINGS],
                    type: OrgUrlType.REGULATORY_FILINGS,
                },
                {
                    name: 'Incident Contact',
                    url: vendorDetails?.urls[OrgUrlType.INCIDENT_RESPONSE],
                    type: OrgUrlType.INCIDENT_RESPONSE,
                },
            ];
        }),
    );

    readonly badgeImageFallback: string = IMAGE_FALLBACK_BASE64;

    get vendorDetails(): VendorDetailsResponse {
        return this.vendorDetails$.value;
    }

    get updatedDate(): Date {
        return this.vendorDetails?.updatedDate;
    }

    get homepage(): string {
        return this.vendorDetails?.homepage;
    }

    get industry(): string {
        return this.vendorDetails?.industry;
    }

    get companySize(): string {
        return OrgSizeLabels[this.vendorDetails?.orgSize];
    }

    get isPubliclyTraded(): boolean {
        return this.vendorDetails?.publiclyTraded;
    }

    get description(): string {
        return this.vendorDetails?.description;
    }

    get foundedDate(): Date {
        return this.vendorDetails?.foundedDate;
    }

    get leadership(): LeadershipContactView[] {
        return this.vendorDetails?.leadership;
    }

    get complianceStandards(): ComplianceStandard[] {
        return this.vendorDetails?.complianceStandards?.sort((a, b) => {
            return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
        });
    }

    get showRiskAdvisories(): boolean {
        return !this.vendorDetails?.managedById;
    }

    getComplianceIcon(standard: ComplianceStandard) {
        switch (standard) {
            case ComplianceStandard.CSA_STAR:
                return 'content/compliance/csa-star-1.svg';
            case ComplianceStandard.CSA_LEVEL2:
                return 'content/compliance/csa-star-2.svg';
            case ComplianceStandard.GDPR:
                return 'content/compliance/gdpr.png';
            case ComplianceStandard.ISO27001:
            case ComplianceStandard.ISO27017:
            case ComplianceStandard.ISO27018:
            case ComplianceStandard.ISO27701:
                return 'content/compliance/iso.png';
            case ComplianceStandard.PCIDSS:
                return 'content/compliance/pci.svg';
            case ComplianceStandard.SOC1:
            case ComplianceStandard.SOC2:
            case ComplianceStandard.SOC3:
                return 'content/compliance/soc2.png';
            case ComplianceStandard.HITRUST:
                return 'content/compliance/hitrust.png';
            case ComplianceStandard.HIPAA:
                return 'content/compliance/hipaa.svg';
            case ComplianceStandard.FEDRAMP:
                return 'content/compliance/fedramp.png';
            case ComplianceStandard.PRIVACY_SHIELD:
                return 'content/compliance/privacyshield.png';
            case ComplianceStandard.GLBA:
                return 'content/compliance/glba.png';
            case ComplianceStandard.CCPA:
                return 'content/compliance/ccpa.png';
        }
    }

    stripLinkProtocol(url: string): string {
        return url.replace(/(^\w+:|^)\/\//, '');
    }

    urlIsFromSafeBase(): boolean {
        return this.vendorDetails?.trustCenter?.type === TrustCenterType.SAFEBASE;
    }
}
