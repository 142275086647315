<div class="tier-stats-wrapper">
    <mat-drawer-container>
        <div class="tier-stats-container">
            @if (vendorTierStats(); as stats) {
                <div class="tier-stat-card">
                    @if (stats.averageInherentRisk > 0) {
                        <div class="number" [ngStyle]="{ color: getRiskColorHash(stats.averageInherentRisk) }">
                            {{ stats.averageInherentRisk * 100 | number: '2.2-2' }}<span class="percent">%</span>
                        </div>
                    } @else {
                        <div class="number">-</div>
                    }
                    <div class="subtitle">Avg. inherent risk</div>
                </div>

                <div class="tier-stat-card">
                    @if (stats.averageResidualRisk > 0) {
                        <div class="number" [ngStyle]="{ color: getRiskColorHash(stats.averageResidualRisk) }">
                            {{ stats.averageResidualRisk * 100 | number: '2.2-2' }}<span class="percent">%</span>
                        </div>
                    } @else {
                        <div class="number">-</div>
                    }
                    <div class="subtitle">Avg. residual risk</div>
                </div>

                <div
                    tabindex="0"
                    class="tier-stat-card"
                    role="button"
                    aria-description="Button to filter relationships down to only those that are onboarded"
                    (click)="applyOnboardedFilter.emit()"
                >
                    <div class="number text-primary">
                        <span>{{ stats.onboardedRelationships || 0 }}</span>
                        <span class="text-md filter">
                            <mat-icon fontSet="material-symbols-outlined">search</mat-icon>
                        </span>
                    </div>
                    <div class="subtitle">Onboarded relationships</div>
                </div>

                <div
                    tabindex="0"
                    class="tier-stat-card last"
                    role="button"
                    aria-description="Button to filter relationships down to only those that have been assessed"
                    (click)="applyAssessedFilter.emit()"
                >
                    <div class="number text-primary">
                        <span>{{ stats.assessedRelationships || 0 }}</span>
                        <div class="text-md filter">
                            <mat-icon fontSet="material-symbols-outlined">search</mat-icon>
                        </div>
                    </div>
                    <div class="subtitle">Assessed vendors</div>
                </div>

                @if (forVendorTier) {
                    <button
                        class="show-description-button"
                        matTooltip="Show/edit tier description"
                        matTooltipShowDelay="1000"
                        type="button"
                        mat-flat-button
                        matTooltipPosition="left"
                        color="primary"
                        (click)="openDrawer()"
                    >
                        <mat-icon class="m-0">description</mat-icon>
                    </button>
                }
            }
        </div>
        @if (forVendorTier) {
            <mat-drawer #drawer mode="over" position="end">
                <div class="description h-100">
                    @if (!!description()) {
                        <p
                            class="mb-0"
                            [ngClass]="{ 'user-select-none': disableDescriptionTooltip }"
                            #descriptionParagraph
                            [matTooltip]="showDescriptionTooltip(descriptionParagraph) ? description() : ''"
                            matTooltipClass="description-tooltip"
                        >
                            {{ description() }}
                        </p>
                        <button class="edit-button" mat-icon-button color="primary" (click)="updateDescription.emit()">
                            <mat-icon fontSet="material-symbols-outlined"> edit </mat-icon>
                        </button>
                    } @else {
                        <button
                            mat-button
                            color="primary"
                            (click)="updateDescription.emit()"
                            aria-label="Add a description to the current vendor tier"
                        >
                            <mat-icon>add</mat-icon> Add description
                        </button>
                    }
                </div>
            </mat-drawer>
        }
    </mat-drawer-container>
</div>
